import axios from "../axios";

export const all = (locale = "tr") => {
    return axios.get(
        "/api/collections/products/entries?filter[site]=" + locale
    );
};

export const find = (slug) => {
    return axios.get("/api/collections/products/entries/" + slug);
};

export const findIds = (ids) => {
    let url = "?";
    for (let i = 0; i < ids.length; i++) {
        url += "productIDs[]=" + ids[i];
        if (i + 1 != ids.length) {
            url += "&";
        }
    }
    return axios.get("/api/products" + url);
};

export const cart = (ids) => {
    let url = "?";
    for (let i = 0; i < ids.length; i++) {
        url += "productIDs[]=" + ids[i];
        if (i + 1 != ids.length) {
            url += "&";
        }
    }
    return axios.get("api/cart" + url);
};

export const search = (locale = "tr", keyword, category = null) => {
    if (keyword) {
        if (category) {
            return axios.get(
                "/api/collections/products/entries?filter[site]=" +
                    locale +
                    "&filter[title:contains]=" +
                    keyword +
                    "&filter[taxonomy:product_category]=" +
                    category
            );
        }
        return axios.get(
            "/api/collections/products/entries?filter[site]=" +
                locale +
                "&filter[title:contains]=" +
                keyword
        );
    }
    if (category) {
        return axios.get(
            "/api/collections/products/entries?filter[site]=" +
                locale +
                "&filter[taxonomy:product_category]=" +
                category
        );
    }
    return axios.get(
        "/api/collections/products/entries?filter[site]=" + locale
    );
};

export const categories = (locale = "tr", id = null) => {
    if (id) {
        return axios.get(
            "/api/taxonomies/product_category/terms?filter[site]=" +
                locale +
                "&filter[parent:is]=" +
                id +
                "&sort=order"
        );
    }
    return axios.get(
        "/api/taxonomies/product_category/terms?filter[site]=" +
            locale +
            "&filter[parent:contains]=ana-kategori"
    );
};

export const category = (locale = "tr", id) => {
    return axios.get(
        "/api/taxonomies/product_category/terms?filter[site:is]=" +
            locale +
            "&filter[id:is]=" +
            id
    );
};

export const categoryBySlug = (locale = "tr", slug) => {
    return axios.get(
        "/api/taxonomies/product_category/terms?filter[site:is]=" +
            locale +
            "&filter[slug:is]=" +
            slug
    );
};

export const submit = (data) => {
    axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
    return axios.post("/!/forms/offer_form", data);
};

export const filter = (url) => {
    // return axios.get('/api/collections/products/entries' + url);
    return axios.get("/api/filter" + url);
};

export const productFilter = (url) => {
    return axios.get("/api/collections/products/entries" + url);
};
