<template>
    <div>
        <!--
  <section class="mb-5">
   <div class="container">
    <div class="row">
     <div class="d-flex justify-content-start align-items-center gap-2 flex-wrap">
      <a href="/" class="text-decoration-none text-body">Anasayfa
      </a>
      <span style="font-size: 30px" class="mr-1">></span>
      <a href="products" class="text-decoration-none mr-1" style="color: black">Ürünler</a>
      <span style="font-size: 30px" class="mr-1">></span>
      <a href="fren-kampanasi" class="text-decoration-none text-danger">
       {{ title }}
      </a>
     </div>
    </div>
   </div>
  </section> -->
        <section class="container mt-1 mb-5 first-banner-color ">
            <div class="row align-items-center justify-content-center">
                <div class="col-12 col-md-7 p-5 product-contain ">
                    <div class="d-flex flex-column p-5">
                        <h1 class="detail-h1">{{ title }} </h1>
                        <div class="hr-blue-product"></div>
                        <p class="mt-3" v-if="(!isEnglish) && (title === 'Şanzıman Grubu' || title === 'Transmission Group')">
                    Avrupa'nın en büyük fren üreticilerinden olan EKU kamyon, otobüs, trailer ve tırlar için yüksek kaliteli fren ürünleri sunmaktadır. Debriyaj diski, debriyaj merkezi, debriyaj baskısı, debriyaj çatalı, debriyaj güçlendiricisi ve debriyaj rulmanı gibi yüksek kaliteli ürünleriyle, araçların performansını optimize ederken, şanzıman sisteminin uzun ömürlü ve verimli çalışmasını sağlar.
                        </p>
                        <p class="mt-3" v-else-if="(!isEnglish) && (title === 'Fren Grubu' || title === 'Brake Group')">
                            Avrupa'nın en büyük fren üreticilerinden olan EKU, kamyon, otobüs, trailer ve tırlar için yüksek kaliteli fren ürünleri sunmaktadır. EKU’nun geniş ürün yelpazesi, fren kampanası, fren diski, kampana balata, disk balata, fren ayar kolu, fren körüğü, fren pabucu, fren kaliperi ve poyra gibi önemli ürünler içerir. Her bir ürün, yüksek dayanıklılık ve mükemmel performans sunarak, ağır taşıma araçlarının güvenliğini ve sürüş kalitesini artırır.
                        </p>
                        <p class="mt-3" v-else-if="(!isEnglish) && (title === 'Motor Grubu' || title === 'Motor Group')">
                            Avrupa'nın en büyük fren üreticilerinden olan EKU, kamyon, otobüs, trailer ve tırlar için yüksek kaliteli motor ürünleri sunmaktadır. EKU’nun motor grubu, yakıt pompası, yağ pompası ve su pompası gibi önemli ürünlerden oluşur. Bu ürünler, motorun verimli çalışmasını ve uzun ömürlü olmasını sağlarken, taşıma araçlarının performansını en üst düzeye çıkarır.
                        </p>
                        <p class="mt-3" v-else-if="!isEnglish">
                        Avrupa'nın en büyük fren üreticilerinden olan EKU kamyon, otobüs, trailer ve tırlar
                        için yüksek kaliteli fren ürünleri sunmaktadır.
                        </p>
                    </div>
                </div>
                <div class="col-12 col-md-5 p-0 product-contain">
                    <div class="d-flex justify-content-end pr-5">
                        <img :src="img" alt="" style="width: 50%;">
                    </div>
                </div>


                <div class="product-contain-mobil">
                    <div class="col-12">
                        <img :src="img" alt="">
                    </div>
                    <div class="col-12">
                        <div class="d-flex flex-column ">
                            <h1 class="detail-h1">{{ title }} </h1>
                            <div class="hr-blue-product"></div>
                            <p class="mt-3" v-if="!isEnglish">Avrupa'nın en büyük fren üreticilerinden olan EKU kamyon, otobüs, trailer
                                ve
                                tırlar için yüksek kaliteli fren ürünleri sunmaktadır.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    export default {
        props: {
            title: {
                type: String,
                default: ''
            },
            img: {
                type: String,
                default: '/images/kampana.jpg'
            },
        },
        data() {
        const currentUrl = window.location.pathname; // URL'in yol kısmını alır
        const firstSegment = currentUrl.split('/')[1]; // İlk segmenti alır (örneğin, 'en')
        const isEnglish = firstSegment === 'en'; // İlk segment 'en' ise true döner
        return {
            isEnglish: isEnglish,
        };
    },
    }
</script>

<style lang="scss" scoped></style>
