<template>
    <div class="product-categories-wrapper">
        <div>
            <mobile-offer-list :locale="locale" :reload="reload" @finish-reload="finishReload"  />
        </div>

        <section class="products products-filter products-general pt-md-0 pt-3 pb-6 mt-0">
            <div class="container">
                <div class="row"></div>
                <div class="row mb-lg-13 mb-md-7 mb-5">
                    <div class="row">
                        <div class="col-lg-9 col-md-10 offset-lg-2 offset-md-1">
                            <div class="hero-wrapper bg-light">
                                <h6 class="text-dark font-weight-bold">{{ taxonomy.title }}</h6>
                            </div>
                            <div class="hero-content">
                                <figure class="mb-md-4 mb-8">
                                    <img class="w-100 d-sm-block d-none filter-group-img" v-if="taxonomy.card_image" :src="taxonomy.card_image.permalink" alt="" />
                                    <img class="w-100 d-sm-none d-block mobile-img" v-if="taxonomy.card_mobile_image" :src="taxonomy.card_mobile_image.permalink" alt="" />
                                </figure>
                                <div v-html="taxonomy.details_content"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <div>
            <mobile-product-filter
                :advanced-search="true"
                :locale="locale"
                :searched-category="category"
                :filtered-category="taxonomy"
                @start-reload="startReload"
                @update-searched-category="updateSearchedCategory"
            />
        </div>

    </div>
</template>

<script>
import * as ProductService from "../../services/ProductService";
import MobileProduct from "./MobileProduct";

export default {
    name: "MobileFilterPage",
    components: {MobileProduct},
    props: {
        locale: {
            required: true,
            type: String
        },
        slug: {
            required: true,
            type: String
        }
    },
    data() {
        return {
            taxonomy: {},
            category: null,
            reload: true,
        }
    },
    created() {
        this.handleTaxonomy();
    },
    methods: {
        async handleTaxonomy() {
            await ProductService.categoryBySlug(this.locale, this.slug)
                .then(response => {
                    this.taxonomy = response.data.data[0];
                })
        },
        finishReload() {
            this.reload = false;
        },
        startReload() {
            this.reload = true;
        },
        updateSearchedCategory(event) {
            this.category = event;
        }
    }
}
</script>

<style scoped>

</style>
