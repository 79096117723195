<template>
    <div class="container">
        <div class="row justify-content-center text-center align-items-center">
            <h1>{{ isEnglish ? 'Other Categories' : 'Kategori Diğer Ürünler' }}</h1>
            <div class="hr-yellow-product"></div>
            <div v-html="slider_items" id="product-carousel" class="owl-carousel owl-theme mt-4"></div>
        </div>
    </div>
</template>

<script>
export default {
    props: ["slider_items"],
    data() {
        const currentUrl = window.location.pathname; // URL'in yol kısmını alır
        const firstSegment = currentUrl.split('/')[1]; // İlk segmenti alır (örneğin, 'en')
        const isEnglish = firstSegment === 'en'; // İlk segment 'en' ise true döner
        return {
            isEnglish: isEnglish,
        };
    },
    mounted() {
        $("#product-carousel").owlCarousel({
            loop: true,
            margin: 10,
            nav: true,
            dots: false,
            autoplay: true,
            autoplayTimeout: 3000,
            autoplayHoverPause: true,
            responsive: {
                0: {
                    items: 1,
                },
                600: {
                    items: 3,
                },
                1000: {
                    items: 3,
                },
            },
        });
    },
};
</script>

<style lang="scss" scoped></style>
