import "../../public/css/main.css";

import FilterPage from "./components/FilterPage";

// require('bootstrap')
import Swiper from "swiper";
import "swiper/swiper-bundle.css";
import SwiperCore, {
    A11y,
    Navigation,
    Pagination,
    EffectFade,
    Keyboard,
    Autoplay,
    Scrollbar,
    EffectFlip,
} from "swiper/core";
import { createApp } from "vue";
import ProductFilter from "./components/ProductFilter";
import OfferList from "./components/OfferList";
import { VueCookieNext } from "vue-cookie-next";
// import ProductsPage from "./components/ProductsPage";
import MobileFilterPage from "./components/mobile/MobileFilterPage";
import MobileProductFilter from "./components/mobile/MobileProductFilter";
import MobileOfferList from "./components/mobile/MobileOfferList";
import MobileProductsPage from "./components/mobile/MobileProductsPage";
import EncryptedContent from "./components/EncryptedContent";

// fren new pages
import Products from "./components/Products/Index.vue";
import ProductsFenGroup from "./components/Products/fengroup/Index.vue";
import ProductsFrenKampanasi from "./components/Products/fengroup/FrenKampanasi.vue";
import ProductsFrenDiski from "./components/Products/fengroup/FrenDiski.vue";
import DiskBalata from "./components/Products/fengroup/DiskBalata.vue";
import Poyra from "./components/Products/fengroup/Poyra.vue";
import FrenPabucu from "./components/Products/fengroup/FrenPabucu.vue";
import FrenAyarKolu from "./components/Products/fengroup/FrenAyarKolu.vue";
import FrenKorugu from "./components/Products/fengroup/FrenKorugu.vue";
import FrenKaliperi from "./components/Products/fengroup/FrenKaliperi.vue";
import KampanaBalata from "./components/Products/fengroup/KampanaBalata.vue";

// Havali fren group
import ProductsHavaliFren from "./components/Products/havafrengroup/Index.vue";
import HavaKurutucusu from "./components/Products/havafrengroup/HavaKurutucusu.vue";
import HavaKurutucusuKartusu from "./components/Products/havafrengroup/HavaKurutucusuKartusu.vue";
import Valfler from "./components/Products/havafrengroup/Valfler.vue";

// Motor group
import ProductsMotor from "./components/Products/motorgroup/Index.vue";
import YakitPompasi from "./components/Products/motorgroup/YakitPompasi.vue";
import SuPompasi from "./components/Products/motorgroup/SuPompasi.vue";
import YagPompasi from "./components/Products/motorgroup/YagPompasi.vue";

// Sanziman group
import ProductsSanziman from "./components/Products/sanzimangroup/Index.vue";
import DebriyajBaskisi from "./components/Products/sanzimangroup/DebriyajBaskisi.vue";
import DebriyajDiski from "./components/Products/sanzimangroup/DebriyajDiski.vue";
import DebriyajCatali from "./components/Products/sanzimangroup/DebriyajCatali.vue";
import DebriyajRulmani from "./components/Products/sanzimangroup/DebriyajRulmani.vue";
import DebriyajMerkezi from "./components/Products/sanzimangroup/DebriyajMerkezi.vue";
import DebriyajGuclendiricisi from "./components/Products/sanzimangroup/DebriyajGuclendiricisi.vue";

// Tryler group
import ProductsTreyler from "./components/Products/trylergroup/Index.vue";
import BesinciTeker from "./components/Products/trylergroup/BesinciTeker.vue";
import DorseAyagi from "./components/Products/trylergroup/DorseAyagi.vue";

// Sensor group
import ProductsSensor from "./components/Products/sensorgroup/Index.vue";
import AbsTekerlekHizSensoru from "./components/Products/sensorgroup/AbsTekerlekHizSensoru.vue";

// can you import all the components inside components/Products folder

import 'datatables.net';
import 'datatables.net-dt';
// import 'bootstrap/dist/css/bootstrap.min.css';
import 'datatables.net-bs4/css/dataTables.bootstrap4.min.css';
import 'jquery'; // Datatables jQuery bağımlılığını sağlamak için
// import 'bootstrap'; // Bootstrap JS dosyasını dahil etmek için
import 'datatables.net-bs4'; // Datatables ile Bootstrap 4'ü entegre etmek içi




const app = createApp({});
app.use(VueCookieNext);
app.component("product-filter", ProductFilter);
app.component("offer-list", OfferList);
app.component("filter-page", FilterPage);
// fren new pages
app.component("products-page", Products);
app.component("products-fen-group", ProductsFenGroup);
app.component("products-fren-kampanasi", ProductsFrenKampanasi);
app.component("products-fren-diski", ProductsFrenDiski);
app.component("products-disk-balata", DiskBalata);
app.component("products-poyra", Poyra);
app.component("products-fren-pabucu", FrenPabucu);
app.component("products-fren-ayar-kolu", FrenAyarKolu);
app.component("products-fren-korugu", FrenKorugu);
app.component("products-fren-kaliperi", FrenKaliperi);
app.component("products-kampana-balata", KampanaBalata);

// Havali fren group
app.component("products-havali-fren", ProductsHavaliFren);
app.component("products-hava-kurutucusu", HavaKurutucusu);
app.component("products-hava-kurutucusu-kartusu", HavaKurutucusuKartusu);
app.component("products-valfler", Valfler);

// Motor group
app.component("products-motor", ProductsMotor);
app.component("products-yakit-pompasi", YakitPompasi);
app.component("products-su-pompasi", SuPompasi);
app.component("products-yag-pompasi", YagPompasi);

// Sanziman group
app.component("products-sanziman", ProductsSanziman);
app.component("products-debriyaj-baskisi", DebriyajBaskisi);
app.component("products-debriyaj-diski", DebriyajDiski);
app.component("products-debriyaj-catali", DebriyajCatali);
app.component("products-debriyaj-rulmani", DebriyajRulmani);
app.component("products-debriyaj-merkezi", DebriyajMerkezi);
app.component("products-debriyaj-guclendiricisi", DebriyajGuclendiricisi);

// Tryler group
app.component("products-treyler", ProductsTreyler);
app.component("products-besinci-teker", BesinciTeker);
app.component("products-dorse-ayagi", DorseAyagi);

// Sensor group
app.component("products-sensor", ProductsSensor);
app.component("products-abs-tekerlek-hiz-sensoru", AbsTekerlekHizSensoru);

app.component("mobile-filter-page", MobileFilterPage);
app.component("mobile-product-filter", MobileProductFilter);
app.component("mobile-offer-list", MobileOfferList);
app.component("mobile-products-page", MobileProductsPage);
app.component("encrypted-content", EncryptedContent);

app.mount("#app");

SwiperCore.use([
    A11y,
    Navigation,
    Pagination,
    EffectFade,
    Keyboard,
    Autoplay,
    Scrollbar,
    EffectFlip,
]);

// HOME SLIDER
new Swiper(".mySwiper", {
    speed: 1000,
    effect: "fade",
    fadeEffect: {
        crossFade: true,
    },
    autoplay: {
        delay: 9000,
        disableOnInteraction: false,
    },

    keyboard: {
        enabled: true,
    },
    pagination: {
        el: ".swiper-pagination",
        clickable: true,

        renderBullet: function (index, className) {
            return (
                '<span class="' + className + '">' + 0 + (index + 1) + "</span>"
            );
        },
    },
});

var swiper = new Swiper(".manufacturing-slider", {
    navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
    },
    effect: "fade",
    fadeEffect: {
        crossFade: true,
    },
});

// HOME PRODUCTS SLIDER
const homeProductsSlider = new Swiper(".home-products-slider", {
    slidesPerView: 1,
    spaceBetween: 30,
    slidesPerGroup: 1,
    loopFillGroupWithBlank: true,
    keyboardControl: true,
    navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
    },
    breakpoints: {
        520: {
            slidesPerView: 2,
            spaceBetween: 20,
        },
        999: {
            slidesPerView: 3,
            spaceBetween: 30,
        },
        1200: {
            slidesPerView: 4,
            spaceBetween: 30,
        },
    },
});
// Update swiper buttons state to fix disabled button problem
$(document).ready(function () {
    homeProductsSlider.navigation.update();
});

//HOME NEWS SLIDER
new Swiper(".home-news-slider", {
    slidesPerView: 1,
    spaceBetween: 30,
    slidesPerGroup: 1,
    loopFillGroupWithBlank: true,
    keyboardControl: true,
    navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
    },
    a11y: true,
    breakpoints: {
        520: {
            slidesPerView: 2,
            spaceBetween: 20,
        },
        999: {
            slidesPerView: 3,
            spaceBetween: 30,
        },
        1200: {
            slidesPerView: 4,
            spaceBetween: 30,
        },
    },
});

//OUR HISTORY SLIDER
new Swiper(".our-history-slider", {
    spaceBetween: 160,
    slidesPerGroupSkip: 1,
    navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
    },
    keyboard: {
        enabled: true,
    },

    scrollbar: {
        el: ".swiper-scrollbar",
        draggable: true,
    },

    breakpoints: {
        576: {
            slidesPerView: 1,
            spaceBetween: 0,
        },
        999: {
            slidesPerView: 1.6,
            spaceBetween: 120,
        },
        1200: {
            slidesPerView: 2.6,
            spaceBetween: 160,
        },
    },
});

//COMPANY DOING MOBILE SLIDER
new Swiper(".company-doing-mobile-slider", {
    navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
    },
});

//QUALITY SYSTEMS MOBILE SLIDER
new Swiper(".quality-systems-mobile-slider", {
    navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
    },
});

//CERTIFICATES MOBILE SLIDER
new Swiper(".certificates-mobile-slider", {
    navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
    },
    effect: "flip",
});

// JQUERY
$(document).ready(function () {
    //Scroll Animation Actived

    $(window).scroll(function () {
        var scrollTop = $(window).scrollTop();
        $(".anim").each(function (i) {
            var $box = $(".anim").eq(i);
            var offset = $box.offset();
            if (scrollTop > offset.top - $(window).height() / 1.2) {
                $box.addClass("active");
            } else {
                $box.removeClass("active");
            }
        });

        ///Scoll on Flowing
        document.body.style.setProperty(
            "--scroll",
            window.pageYOffset /
                (document.body.offsetHeight - window.innerHeight)
        );
    });

    // Loader Page Animation
    $(".loader").addClass("active");

    // MENU TOGGLE
    $(".toggle, .menu-btn").on("click", function () {
        $(".toggle").toggleClass("on-off");
        $(".navbar-screen").toggleClass("active");
        $(".navbar-logo").toggleClass("active");
        $(".close-logo").toggleClass("active");
        $(".close-navbar").toggleClass("active");
        $(".header").toggleClass("active");
        $(".header-container").toggleClass("active");
        $("body").toggleClass("overflow-hidden");
        let path = window.location.pathname;
        path = path.split("/");
        if (path[1] === "en") {
            $(".menu-btn").text(
                $(".menu-btn").text().trim() == "Menu" ? "Close" : "Menu"
            );
        } else {
            $(".menu-btn").text(
                $(".menu-btn").text().trim() == "Menü" ? "Kapat" : "Menü"
            );
        }
    });

    //Navbar Toggle

    function onStartFilter() {
        $(".search-filter").toggleClass("active");
    }
    onStartFilter();

    function onStartOpen() {
        $(".hamburger").toggleClass("active");
        $(".hamburger").toggleClass("filter-menu-active");
        $(".filter-body").toggleClass("active");
    }
    // onStartOpen()

    function filterProduct() {
        $(".hamburger").toggleClass("active");
        $(".hamburger").toggleClass("filter-menu-active");
        $(".filter-body").toggleClass("active");
        $(".search-filter").toggleClass("active");

        $('.filter-body-mobile[data-filter="product-group"]').toggleClass(
            "active"
        );

        $(".f-2").removeClass("active");
        $(".mobile-filter-item").removeClass("active");
        $(".filter-group").removeClass("active");
    }
    $(".filter-button").on("click", filterProduct);

    $(".open-search").on("click", function () {
        $(".search-screen").toggleClass("active");
    });

    $(".fa-info").on("click", function () {
        $(this).parent().parent().parent().parent().addClass("active");
    });

    // $('.list-toggle').on('click', function () {
    //     $('.offer-list-dropdown').toggleClass('active')
    // })

    $(".product-item .fa-info").on("click", function () {
        $(this).parent().parent().toggleClass("active");
    });

    $(".read-more-btn").on("click", function () {
        $(this).parent().addClass("active");
    });

    $(".minus").click(function () {
        var $input = $(this).parent().find(".pieces-input");
        var count = parseInt($input.val()) - 1;
        count = count < 1 ? 1 : count;
        $input.val(count);
        $input.change();
        return false;
    });
    $(".plus").click(function () {
        var $input = $(this).parent().find(".pieces-input");
        $input.val(parseInt($input.val()) + 1);
        $input.change();
        return false;
    });

    ///Selected Language
    function selectedValueHidden(getValue) {
        $(".language-item").each(function () {
            if (getValue === $(this).text().trim()) {
                $(this).css("display", "none");
            } else {
                $(this).css("display", "block");
            }
        });
    }

    selectedValueHidden(document.documentElement.lang.toUpperCase());

    //PersonAccordion
    $(".collapse")
        .on("show.bs.collapse", function () {
            $(this).parents(".col-md-6").addClass("wide");
        })
        .on("hide.bs.collapse", function () {
            $(this).parents(".col-md-6").removeClass("wide");
        });
    $(".collapse").on("shown.bs.collapse", function (e) {
        var $card = $(this).closest(".accordion-col-custom");
        var $open = $($(this).data("parent")).find(".collapse.show");

        var additionalOffset = 0;
        if (
            $card.prevAll().filter($open.closest(".accordion-col-custom"))
                .length !== 0
        ) {
            additionalOffset = $open.height();
        }
        $("html,body").animate(
            {
                scrollTop: $card.offset().top - additionalOffset,
            },
            500
        );
    });

    ///Video Company

    $(document).on("click", ".js-videoPoster, .play-text", function (e) {
        e.preventDefault();
        var poster = $(this);
        var wrapper = poster.closest(".js-videoWrapper");
        videoPlay(wrapper);
    });

    function videoPlay(wrapper) {
        var iframe = wrapper.find(".js-videoIframe");
        var src = iframe.data("src");
        wrapper.addClass("videoWrapperActive");
        iframe.attr("src", src);
    }

    const file = document.querySelector("#resume");
    if (file) {
        file.addEventListener("change", (e) => {
            const [file] = e.target.files;
            const { name: fileName, size } = file;
            const fileSize = (size / 1000).toFixed(2);
            const fileNameAndSize = `${fileName} - ${fileSize}KB`;
            document.querySelector(".file-name").textContent = fileNameAndSize;
        });
    }
});
