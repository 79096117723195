<template>
    <div class="row">
        <div class="col-lg-9 col-md-10 offset-lg-2 offset-md-1">
            <div class="d-flex justify-content-between position-relative">
                <h1
                    class="section-secondary-title-v2"
                    v-if="this.locale === 'tr'"
                >
                    ürünler
                </h1>
                <h1 class="section-secondary-title-v2" v-else>products</h1>
                <div
                    class="dropdown offer-list-dropdown"
                    :class="openOfferList ? 'active' : null"
                    ref="listWrapper"
                >
                    <div
                        class="offer-list-dropdown-wrapper d-flex align-items-center mt-lg-4 mt-1 pl-7"
                    >
                        <button
                            v-if="this.locale == 'en'"
                            ref="backButton"
                            class="border-0 bg-transparent offer-back-btn"
                            :class="proceed ? 'active' : null"
                            @click="proceedButton"
                        >
                            <i class="fas fa-chevron-left"></i>
                            Back
                        </button>
                        <button
                            v-else
                            ref="backButton"
                            class="border-0 bg-transparent offer-back-btn"
                            :class="proceed ? 'active' : null"
                            @click="proceedButton"
                        >
                            <i class="fas fa-chevron-left"></i>
                            Geri
                        </button>

                        <button
                            ref="productButton"
                            class="btn dropdown-toggle list-toggle ml-auto d-flex align-items-center pr-0"
                            type="button"
                            id="dropdownMenuButton"
                            aria-haspopup="true"
                            aria-expanded="false"
                            @click="closeEditOfferList"
                        >
                            <span
                                class="outline-btn red font-col-lg-4-bold mr-3"
                                v-if="this.locale === 'tr'"
                                >ÜRÜN SORGUSU</span
                            >
                            <span
                                class="outline-btn red font-col-lg-4-bold mr-3"
                                v-else
                                >PRODUCT ENQUIRY</span
                            >
                            <span class="pieces btn btn-secondary mr-2">{{
                                count
                            }}</span>
                        </button>
                    </div>

                    <div
                        ref="products"
                        class="custom-dropdown-pages position-relative pl-8 pr-4 py-6 h-100"
                    >
                        <!-- 1 -->
                        <div class="offer-list" v-if="!(proceed || submitted)">
                            <div v-if="isShowOfferListItems">
                                <ul class="list-inline">
                                    <li
                                        v-if="this.locale == 'en'"
                                        class="d-flex align-items-center justify-content-between pr-4"
                                    >
                                        <p class="head-title">ORIGINAL NO.</p>
                                        <p class="head-title">PRODUCT NAME</p>
                                        <p class="head-title">PIECES</p>
                                    </li>
                                    <li
                                        v-else
                                        class="d-flex align-items-center justify-content-between pr-4"
                                    >
                                        <p class="head-title">ORJİNAL NO</p>
                                        <p class="head-title">ÜRÜN ADI</p>
                                        <p class="head-title">PARÇALAR</p>
                                    </li>
                                </ul>
                                <ul class="list-inline list-all pr-4">
                                    <li
                                        v-for="product in products"
                                        class="d-flex align-items-start justify-content-between"
                                        :key="product.id"
                                    >
                                        <OfferProduct
                                            :product="product"
                                            @increase-count="increaseCount"
                                            @decrease-count="decreaseCount"
                                        />
                                    </li>
                                </ul>
                                <div class="d-flex justify-content-end mt-6">
                                    <button
                                        class="outline-btn red proced font-weight-bold text-secondary d-inline-block view-all-btn bg-transparent border-0 px-0 mr-4"
                                        ref="proceedButton"
                                        @click="proceedButton"
                                        v-if="this.locale == 'en'"
                                    >
                                        PROCEED
                                    </button>
                                    <button
                                        class="outline-btn red proced font-weight-bold text-secondary d-inline-block view-all-btn bg-transparent border-0 px-0 mr-4"
                                        ref="proceedButton"
                                        @click="proceedButton"
                                        v-else
                                    >
                                        İLERLE
                                    </button>
                                </div>
                            </div>
                            <p v-else class="text-center">
                                <span v-if="this.locale == 'en'"
                                    >Your Product Enquiry is empty.</span
                                ><span v-else>Ürün Sorgunuz Boş</span>
                            </p>
                        </div>
                        <!-- 2 -->
                        <div
                            class="send-my-offer-list bg-white w-100 mb-5 py-4 px-7"
                            :class="proceed ? 'active' : null"
                        >
                            <form
                                class="send-my-offer-form"
                                ref="form"
                                @submit.prevent="validate"
                            >
                                <div class="form-row">
                                    <input
                                        type="hidden"
                                        name="products"
                                        id="products"
                                        :value="this.productString"
                                    />
                                </div>

                                <div class="form-row mb-3">
                                    <div class="col">
                                        <label
                                            v-if="this.locale == 'tr'"
                                            for="nameandsurname"
                                            >İsminiz ve Soyisminiz</label
                                        >
                                        <label v-else for="nameandsurname"
                                            >Your Name and Surname</label
                                        >
                                        <input
                                            type="text"
                                            required
                                            :oninvalid="
                                                this.locale == 'en'
                                                    ? `setCustomValidity('Please fill in the marked fields.')`
                                                    : `setCustomValidity('Lütfen işaretli yerleri doldurunuz.')`
                                            "
                                            oninput="setCustomValidity('')"
                                            v-model="model.name"
                                            name="name"
                                            class="form-control"
                                            :placeholder="
                                                this.locale == 'en'
                                                    ? 'Your Name and Surname'
                                                    : 'İsminiz Ve Soyisminiz'
                                            "
                                        />
                                    </div>
                                </div>
                                <div class="form-row mb-3">
                                    <div class="col">
                                        <label
                                            v-if="this.locale == 'en'"
                                            for="company"
                                            >The Company Name</label
                                        >
                                        <label for="company" v-else
                                            >Şirket Adı</label
                                        >
                                        <input
                                            type="text"
                                            required
                                            :oninvalid="
                                                this.locale == 'en'
                                                    ? `setCustomValidity('Please fill in the marked fields.')`
                                                    : `setCustomValidity('Lütfen işaretli yerleri doldurunuz.')`
                                            "
                                            oninput="setCustomValidity('')"
                                            name="company"
                                            v-model="model.company"
                                            class="form-control"
                                            :placeholder="
                                                this.locale == 'en'
                                                    ? 'The Company Name'
                                                    : 'Şirket Adı'
                                            "
                                        />
                                    </div>
                                </div>
                                <div class="form-row mb-3">
                                    <div class="col">
                                        <label
                                            for="email"
                                            v-if="this.locale == 'en'"
                                            >E-Mail Adress</label
                                        >
                                        <label for="email" v-else
                                            >E-Mail Adresi</label
                                        >
                                        <input
                                            type="text"
                                            required
                                            :oninvalid="
                                                this.locale == 'en'
                                                    ? `setCustomValidity('Please fill in the marked fields.')`
                                                    : `setCustomValidity('Lütfen işaretli yerleri doldurunuz.')`
                                            "
                                            oninput="setCustomValidity('')"
                                            name="email"
                                            v-model="model.email"
                                            class="form-control"
                                            :placeholder="
                                                this.locale == 'en'
                                                    ? 'E-Mail Adress'
                                                    : 'E-Mail adresi'
                                            "
                                        />
                                    </div>
                                </div>
                                <div class="form-row mb-5">
                                    <div class="col">
                                        <label
                                            for="telephone"
                                            v-if="this.locale == 'en'"
                                            >Telephone</label
                                        >
                                        <label for="telephone" v-else
                                            >Telefon</label
                                        >
                                        <input
                                            type="text"
                                            required
                                            :oninvalid="
                                                this.locale == 'en'
                                                    ? `setCustomValidity('Please fill in the marked fields.')`
                                                    : `setCustomValidity('Lütfen işaretli yerleri doldurunuz.')`
                                            "
                                            oninput="setCustomValidity('')"
                                            name="phone"
                                            v-model="model.phone"
                                            class="form-control"
                                            :placeholder="
                                                this.locale == 'en'
                                                    ? 'Telephone'
                                                    : 'Telefon'
                                            "
                                        />
                                    </div>
                                </div>

                                <div>
                                    <label
                                        class="control control-checkbox mb-3"
                                        v-if="this.locale == 'en'"
                                    >
                                        I have read and accept the
                                        <a
                                            href="/en/data_protection_notice_on_the_offer_form"
                                            target="_blank"
                                        >
                                            <span class="text-secondary"
                                                >Data Protection Notice On The
                                                Offer Form</span
                                            >
                                        </a>
                                        and

                                        <a
                                            href="/commercial-privacy"
                                            target="blank"
                                        >
                                            <span class="text-secondary"
                                                >Statement of Explicit Consent
                                                for Commercial Electronic
                                                Messages</span
                                            >
                                        </a>
                                        <input
                                            type="checkbox"
                                            :oninvalid="
                                                this.locale == 'en'
                                                    ? `setCustomValidity('Please tick the box I have read and accept.')`
                                                    : `setCustomValidity('Lütfen okudum ve kabul ediyorum kutusunu işaretleyiniz.')`
                                            "
                                            oninput="setCustomValidity('')"
                                            name="consent"
                                            v-model="model.i_have_read_i_accept"
                                            required
                                        />
                                        <div class="control_indicator"></div>
                                    </label>

                                    <label
                                        class="control control-checkbox"
                                        v-else
                                    >
                                        <a
                                            href="/data_protection_notice_on_the_offer_form"
                                            target="blank"
                                        >
                                            <span class="text-secondary"
                                                >Teklif Formundaki </span
                                            >
                                        </a>
                                        Veri Koruma Bildirimini ve
                                        <a
                                            href="/commercial-privacy"
                                            target="blank"
                                        >
                                            <span class="text-secondary"
                                                >Ticari Elektronik İletiler İçin
                                                Açık Rıza Beyanını</span
                                            >
                                        </a>
                                        okudum kabul ediyorum.
                                        <input
                                            type="checkbox"
                                            :oninvalid="
                                                this.locale == 'en'
                                                    ? `setCustomValidity('Please tick the box I have read and accept.')`
                                                    : `setCustomValidity('Lütfen okudum ve kabul ediyorum kutusunu işaretleyiniz.')`
                                            "
                                            oninput="setCustomValidity('')"
                                            name="email_information"
                                            v-model="model.i_have_read_i_accept"
                                            required
                                        />
                                        <div class="control_indicator"></div>
                                    </label>
                                </div>
                                <!--
                                <vue-recaptcha
                                    ref="recaptcha"
                                    @verify="submit"
                                    @expire="console.log($event)"
                                    @fail="console.log($event)"
                                    size="invisible"
                                    sitekey="6LfP6ZweAAAAAGJzW5oHP0mEpFFtuTtdXKsp6irv"
                                >
                                </vue-recaptcha>
                            -->
                                <div
                                    class="g-recaptcha"
                                    data-sitekey="6LfP6ZweAAAAAGJzW5oHP0mEpFFtuTtdXKsp6irv"
                                ></div>
                                <button
                                    v-if="this.locale == 'en'"
                                    class="outline-btn send-my-offer-list-btn red font-weight-bold text-secondary d-inline-block view-all-btn bg-transparent border-0 px-0 mt-5"
                                >
                                    SEND MY PRODUCT ENQUIRY
                                </button>
                                <button
                                    v-else
                                    class="outline-btn send-my-offer-list-btn red font-weight-bold text-secondary d-inline-block view-all-btn bg-transparent border-0 px-0 mt-5"
                                >
                                    ÜRÜN SORGUMU GÖNDER
                                </button>
                            </form>
                        </div>
                        <!-- 3 -->
                        <div
                            class="offer-list-success bg-white py-7 px-7"
                            :class="submitted ? 'active' : null"
                            v-if="this.locale === 'en'"
                        >
                            <h2 class="text-secondary">thank you!</h2>
                            <p>
                                Your list has been delivered to your e-mail
                                address. For more information about our products
                                and services, please contact us.
                            </p>
                            <a
                                class="outline-btn red font-weight-bold d-inline-block"
                                href="/contact-us"
                                >CONTACT US</a
                            >
                        </div>
                        <div
                            class="offer-list-success bg-white py-7 px-7"
                            :class="submitted ? 'active' : null"
                            v-else
                        >
                            <h2 class="text-secondary">teşekkürler!</h2>
                            <p>
                                Listeniz e-mail adresinize gönderildi.
                                Ürünlerimiz ve servislerimiz hakkında daha fazla
                                bilgi almak için lütfen bizimle iletişime geçin.
                            </p>
                            <a
                                class="outline-btn red font-weight-bold d-inline-block"
                                href="/contact-us"
                                >Bizimle iletişime geçin</a
                            >
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import * as ProductService from "../services/ProductService";
import * as OtherPagesService from "../services/OtherPagesService";
import OfferProduct from "./OfferProduct";
import { stringify } from "querystring";
import VueRecaptcha from "vue3-recaptcha2";

export default {
    name: "OfferList",
    components: { OfferProduct, VueRecaptcha },
    props: {
        locale: {
            required: true,
            type: String,
        },
        reload: {
            required: true,
            type: Boolean,
        },
    },
    data() {
        return {
            openOfferList: false,
            products: [],
            count: 0,
            checkboxUrlPageIds: [
                "185b6a1c-4012-401b-bd37-cf7dd2d00090",
                "7e715499-5010-4cdf-a3b1-a2bedca3cf8e",
            ],
            model: {
                name: "",
                company: "",
                email: "",
                phone: "",
                i_have_read_i_accept: false,
                products: "",
            },
            checkboxUrls: [],
            proceed: false,
            submitted: false,
            productString: "",
        };
    },
    created() {
        this.reloadOfferList();
    },
    methods: {
        proceedButton() {
            this.proceed = !this.proceed;
            this.productCart();
        },
        closeEditOfferList() {
            this.openOfferList = !this.openOfferList;
            this.proceed = false;
        },
        toggleOfferList() {
            this.openOfferList = !this.openOfferList;
            this.proceed = false;
            this.submitted = false;
        },
        reloadOfferList() {
            this.products = [];
            let products = this.$cookie.getCookie("products");
            if (products) {
                let list = products.split(",");
                products.split(",").forEach((product) => {
                    ProductService.find(product).then((response) => {
                        let model = response.data.data;
                        if (model) {
                            model.count = list.filter(
                                (p) => p === model.id
                            ).length;
                            if (!this.products.find((p) => p.id === model.id)) {
                                this.products.push(model);
                            }
                        }
                    });
                });
                this.count = list.length;
            } else {
                this.count = 0;
            }
            this.$emit("finish-reload");
        },
        fetchCheckboxDocumentUrls() {
            this.checkboxUrls = [];
            checkboxUrlPageIds.split(",").forEach((id) => {
                const pageData = OtherPagesService.find(id);
            });
        },
        increaseCount() {
            this.count++;
        },
        decreaseCount() {
            this.count--;
        },
        handleProductNames() {
            let productNames = "";
            this.products.forEach((product) => {
                productNames += product.title + ", ";
            });
            this.model.products = productNames;
        },
        productCart() {
            this.productString = "";
            let productsCookie = this.$cookie.getCookie("products");
            if (productsCookie) {
                // get unique array
                let list = productsCookie.split(",");
                ProductService.cart(list).then((response) => {
                    let values = Object.values(response.data);
                    for (
                        let i = 0;
                        i < Object.keys(response.data).length;
                        i++
                    ) {
                        ProductService.find(Object.keys(response.data)[i]).then(
                            (findResponse) => {
                                this.productString +=
                                    findResponse.data.data["oe_number"] +
                                    " adet : " +
                                    values[i] +
                                    "   |  ";
                            }
                        );
                    }
                });
            }
        },
        validate() {
            if (window.grecaptcha.render) {
                this.$refs.recaptcha.execute();
            }
        },
        async submit(token) {
            this.$refs.recaptcha.reset();
            let fData = new FormData(this.$refs.form);
            fData.append("g-recaptcha-response", token);

            try {
                const result = await this.$validator.validateAll();
                if (!result) {
                    return;
                }
            } catch (ex) {
                this.handleProductNames();
                ProductService.submit(fData).then((response) => {
                    this.proceed = false;
                    this.submitted = true;
                    this.$cookie.removeCookie("products");
                    this.$nextTick(() => this.reloadOfferList());
                    this.$emit("finish-reload");
                });
            }
        },

        documentProductClick(e) {
            try {
                let el = this.$refs.products;
                let elButton = this.$refs.productButton;
                let elBackButton = this.$refs.backButton;
                let elProceedButton = this.$refs.proceedButton;
                let elListWrapper = this.$refs.listWrapper;
                let target = e.target;
                if (
                    el !== target &&
                    !el.contains(target) &&
                    !elButton.contains(target) &&
                    !elBackButton.contains(target) &&
                    !elProceedButton.contains(target) &&
                    !elListWrapper.contains(target)
                ) {
                    this.openOfferList = false;
                    this.proceed = false;
                    this.submitted = false;
                }
            } catch (error) {}
        },
    },
    beforeMount() {
        document.addEventListener("click", this.documentProductClick);
    },
    beforeDestroy() {
        document.removeEventListener("click", this.documentProductClick);
    },
    watch: {
        reload: function () {
            if (this.reload === true) {
                this.reloadOfferList();
            }
        },
    },
    computed: {
        isShowOfferListItems() {
            return this.count > 0;
        },
    },
};
</script>

<style scoped></style>
