<template>
    <div>
        <div class="d-flex flex-column w-100" v-if="page">
            <div class="d-flex" v-html="page.content"></div>
            <div class="d-flex" v-if="page.assets">
                <div v-for="asset in page.assets">
                    <a :href="asset.permalink" target="_blank">{{ page.title }}</a>
                </div>
            </div>
        </div>
        <div class="d-flex w-100" v-else>
                <div class="input-wrapper max-w-472px w-100" v-if="this.locale === 'tr'">
                <label class="mb-0 font-s-12px" for="email">Şifre</label>
                <div class="d-flex align-items-md-center align-items-start flex-md-row flex-column">
                    <input type="password" @input="clearError" v-model="password" class="form-control input-style mr-md-5 mb-md-0 mb-5" placeholder="şifre" />
                    <button class="outline-btn btn pl-0 red font-weight-bold d-inline-block" @click="handlePasswordIsCorrect">GÖNDER</button>
                </div>
                <p class="d-flex font-s-14px mt-1 text-danger" v-if="error">{{error}}</p>
            </div>
                <div class="input-wrapper max-w-472px w-100" v-else>
                <label class="mb-0 font-s-12px" for="email">Password</label>
                <div class="d-flex align-items-md-center align-items-start flex-md-row flex-column">
                    <input type="password" @input="clearError" v-model="password" class="form-control input-style mr-md-5 mb-md-0 mb-5" placeholder="password" />
                    <button class="outline-btn btn pl-0 red font-weight-bold d-inline-block" @click="handlePasswordIsCorrect">SEND</button>
                </div>
                <p class="d-flex font-s-14px mt-1 text-danger" v-if="error">{{error}}</p>
            </div>
        </div>
    </div>
</template>

<script>
import * as ProtectedPageService from '../services/ProtectedPageService';

export default {
    name: "EncryptedContent",
    props: {
       id: {
           required: true,
           type: String
       },
        locale: {
            required: true,
            type: String
        },
    },
    data() {
        return {
            page: null,
            password: null,
            error: null
        }
    },
    methods: {
        handlePasswordIsCorrect() {
            ProtectedPageService.find(this.id, this.password)
                .then(response => {
                    this.page = response.data.data;
                    if (this.page === null) {
                        this.error = 'The password is invalid';
                        this.password = null;
                    }
                });
        },
        clearError() {
            this.error = null;
        }
    }
}
</script>

<style scoped>

</style>
