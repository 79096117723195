import axios from "../axios";

export const all = (locale = 'tr') => {
    return axios.get('/api/taxonomies/applications/terms?filter[site]=' + locale);
};

export const find = (slug) => {
    return axios.get('/api/taxonomies/applications/terms/' + slug);
};

export const categories = (locale = 'tr', id = null) => {
    if ( id ) {
        return axios.get('/api/taxonomies/applications/terms?filter[site]=' + locale + '&filter[parent:contains]=' + id);
    }
    return axios.get('/api/taxonomies/applications/terms?filter[site]=' + locale + '&filter[parent:is]=');
};

export const category = (locale = 'tr', id) => {
    return axios.get('/api/taxonomies/applications/terms?filter[site]=' + locale + '&filter[parent:contains]=' + id);
};
