<template>
    <div class="news-detail pt-lg-0 pt-5 pb-lg-25 pb-sm-7 pb-20">
        <section class="">
            <div class="container">
                <div class="row">
                    <div class="d-flex justify-content-start align-items-center gap-2 flex-wrap">
                        <a href="/" class="text-decoration-none mr-1" style="color: black;"> {{ isEnglish ? "Home"
                            : "Anasayfa" }} </a>
                        <span style="font-size: 30px;" class="mr-1">></span>
                        <a href="products" class="text-decoration-none text-body"> {{ isEnglish ? "Products" :
                            "Ürünler" }} </a>
                        <span style="font-size: 30px" class="mr-1">></span>
                        <a :href="isEnglish ? 'brake-group' : 'fren-grubu'" class="text-decoration-none text-danger">{{
                            isEnglish ? "Brake Group" :
                                "Fren Grubu" }}
                        </a>
                    </div>
                </div>
            </div>
        </section>
        <Header :title="isEnglish ? 'Brake Group' :
                                'Fren Grubu'" img="/images/fren-grubu.png" />

        <section class="mb-5">
            <div id="menu-row" class="container-fluid">
                <div class="row">
                    <!-- Menu Başlangıç  -->
                    <div class="col-12 col-md-3">
                        <div class="text-center text-white p-2"
                            style="background-color: #ef353d; border-radius: 5px 5px 0px 0px;">
                            <span v-if="isEnglish">PRODUCT CATEGORIY</span>
                            <span v-else>ÜRÜN KATEGORİLERİ</span>
                        </div>
                        <div class="filter-menu" id="filter-menu"
                            style="background-color: #f8f8f8; border-radius: 0px 0px 5px 5px; margin-bottom:85px !important;">
                            <ul class="list-style-none p-0">
                                <li v-for="(menu, index) in menus" :key="index" class="menu-list-type">
                                    <div class="d-flex justify-content-between">
                                        <a :href="isEnglish ? menu.urlEn : menu.url"
                                            :class="{ 'link-active': isActive(isEnglish ? menu.urlEn : menu.url) }"
                                            class="text-decoration-none menu-list-type"
                                            @click="setActive(isEnglish ? menu.urlEn : menu.url)">
                                            {{ isEnglish ? menu.nameEn : menu.name }}
                                        </a>
                                        <span :id="'menu-plus' + (index + 1)" @click="toggleMenu(index + 1)"
                                            :class="['plus-color']" style="font-size: 30px;">
                                            {{ isMenuOpen[index] ? '-' : '+' }}
                                        </span>
                                    </div>
                                    <ul :id="'open-menu-' + (index + 1)" class="list-style-none" :class="{
                                        'open-menu-passive': !isMenuOpen[index], 'open-menu-active': isMenuOpen[
                                            index]
                                    }">
                                        <li v-for="(submenu, subIndex) in menu.submenus" :key="subIndex"
                                            class="menu-list-type">
                                            <a :class="{ 'link-active': isActive(isEnglish ? submenu.urlEn : submenu.url) }"
                                                :href="isEnglish ? submenu.urlEn : submenu.url"
                                                class="text-decoration-none menu-list-type">
                                                {{ isEnglish ? submenu.nameEn : submenu.name }}
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <!-- Menu Bitiş  -->
                    <div class="col-12 col-md-8 ">
                        <div class="row align-items-center ">
                            <div v-for="(product, index) in products" :key="index"
                                class="col-12 col-md-3 mb-6 banner-product">
                                <div class="product-banner">
                                    <a :href="isEnglish ? product.urlEn : product.url"
                                        class="text-decoration-none product-link d-flex align-items-center text-center justify-content-center flex-column">
                                        <img :src="product.image" class="img-filter " style="width: 90% !important;" />
                                        <p
                                            style="background-color: #f8f8f8;    padding: 10px;width: 100%;margin-bottom: 0px;border-radius: 0px 0px 5px 5px;">
                                            {{ isEnglish ? product.nameEn : product.name }}</p>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>



                </div>
            </div>
        </section>


    </div>
</template>

<script>
import Header from '../../Header.vue';

export default {
    data() {
        const currentUrl = window.location.pathname; // URL'in yol kısmını alır
        const firstSegment = currentUrl.split('/')[1]; // İlk segmenti alır (örneğin, 'en')
        const isEnglish = firstSegment === 'en'; // İlk segment 'en' ise true döner
        return {
            isMenuOpen: Array(6).fill(false),
            isEnglish: isEnglish,
            products: [{
                name: 'Fren Kampanası',
                nameEn: 'Brake Drum',
                url: 'fren-kampanasi',
                urlEn: 'brake-drum',
                image: '/images/fren-kampanasi-nedir.png',
                alt: 'Fren Kampanası',
            },
            {
                name: 'Fren Diski',
                nameEn: 'Brake Disc',
                url: 'fren-diski',
                urlEn: 'brake-disc',
                image: '/images/fren-diski-nedir.png',
                alt: 'Fren Diski Nedir',
            },
            {
                name: 'Kampana Balata',
                nameEn: 'Brake Lining',
                url: 'kampana-balata',
                urlEn: 'brake-lining',
                image: '/images/kampana-balata-nedir.png',
                alt: 'Kampana Balata Nedir?',
            },
            {
                name: 'Disk Balata',
                nameEn: 'Brake Pad',
                url: 'disk-balata',
                urlEn: 'brake-pad',
                image: '/images/disk-balata-nedir.png',
                alt: 'Disk Balata Nedir?',
            },
            {
                name: 'Fren Ayar Kolu',
                nameEn: 'Slack Adjuster',
                url: 'fren-ayar-kolu',
                urlEn: 'slack-adjuster',
                image: '/images/fren-ayar-kolu-nedir.png',
                alt: 'Fren Ayar Kolu Nedir?',
            },
            {
                name: 'Fren Körüğü',
                nameEn: 'Brake Chamber',
                url: 'fren-korugu',
                urlEn: 'brake-chamber',
                image: '/images/fren-korugu-nedir.png',
                alt: 'Fren Körüğü Nedir',
            },
            {
                name: 'Fren Pabucu',
                nameEn: 'Brake Shoe',
                url: 'fren-pabucu',
                urlEn: 'brake-shoe',
                image: '/images/fren-pabucu.png',
                alt: 'Fren Pabucu',
            },
            {
                name: 'Fren Kaliperi',
                nameEn: 'Brake Caliper',
                url: 'fren-kaliperi',
                urlEn: 'brake-caliper',
                image: '/images/fren-kaliperi.png',
                alt: 'Fren Kaliperi',
            },
            {
                name: 'Poyra',
                nameEn: 'Wheelhub',
                url: 'poyra',
                urlEn: 'wheelhup',
                image: '/images/poyra.png',
                alt: 'Poyra',
            },
            ],
            menus: [
                {
                    name: "FREN GRUBU",
                    nameEn: "BBRAKE GROUP",
                    url: "fren-grubu",
                    urlEn: "brake-group",
                    submenus: [
                        { name: "FREN KAMPANASI", nameEn: "BRAKE DRUM", url: "fren-kampanasi", urlEn: "brake-drum" },
                        { name: "FREN DİSKİ", nameEn: "BRAKE DİSC", url: "fren-diski", urlEn: "brake-disc" },
                        { name: "KAMPANA BALATA", nameEn: "BRAKE LİNİNG", url: "kampana-balata", urlEn: "brake-lining" },
                        { name: "DİSK BALATA", nameEn: "BRAKE PAD", url: "disk-balata", urlEn: "brake-pad" },
                        { name: "FREN AYAR KOLU", nameEn: "SLACK ADJUSTER", url: "fren-ayar-kolu", urlEn: "slack-adjuster" },
                        { name: "FREN KÖRÜĞÜ", nameEn: "BRAKE CHAMBER", url: "fren-korugu", urlEn: "brake-chamber" },
                        { name: "FREN PABUCU", nameEn: "BRAKE SHOE", url: "fren-pabucu", urlEn: "brake-shoe" },
                        { name: "FREN KALİPERİ", nameEn: "BRAKE CALİPER", url: "fren-kaliperi", urlEn: "brake-caliper" },
                        { name: "POYRA", nameEn: "WHELLHUB", url: "poyra", urlEn: "wheelhub" },
                    ],
                },
                {
                    name: "HAVALI FREN GRUBU",
                    nameEn: "AIR BRAKE SYSTEM",
                    urlEn: "air-brake-system",
                    url: "havali-fren-grubu",
                    submenus: [
                        {
                            name: "HAVA KURUTUCUSU KARTUŞU",
                            nameEn: "AIR DRYER CARTRIDGE",
                            urlEn: "air-dryer-cartridge",
                            url: "hava-kurutucusu-kartusu",
                        },
                        { name: "HAVA KURUTUCUSU", nameEn: "AIR DRYER", urlEn: "air-dryer", url: "hava-kurutucusu" },
                        { name: "VALFLER", nameEn: "VALVES", urlEn: "valves", url: "valfler" },
                    ],
                },
                {
                    name: "MOTOR GRUBU",
                    nameEn: "MOTOR GROUP",
                    urlEn: "motor-group",
                    url: "motor-grubu",
                    submenus: [
                        { name: "YAKIT POMPASI", nameEn: "FUL PUMP", urlEn: "ful-pump", url: "yakit-pompasi" },
                        { name: "YAĞ POMPASI", nameEn: "OIL PUMP", urlEn: "oil-pump", url: "yag-pompasi" },
                        { name: "SU POMPASI", nameEn: "WATER PUMP", urlEn: "water-pump", url: "su-pompasi" },
                    ],
                },
                {
                    name: "ŞANZIMAN GRUBU",
                    nameEn: "TRANSMISSION GROUP",
                    urlEn: "transmission-group",
                    url: "sanziman-grubu",
                    submenus: [
                        { name: "DEBRİYAJ DİSKİ", nameEn: "CLUTCH DISC", urlEn: "clucth-disc", url: "debriyaj-diski" },
                        { name: "DEBRİYAJ MERKEZİ", nameEn: "CLUTCH MASTER", urlEn: "clutch-master", url: "debriyaj-merkezi" },
                        { name: "DEBRİYAJ BASKISI", nameEn: "CLUTCH PRESSURE", urlEn: "clutch-pressure", url: "debriyaj-baskisi" },
                        { name: "DEBRİYAJ ÇATALI", nameEn: "CLUTCH FORK", urlEn: "clutch-fork", url: "debriyaj-catali" },
                        {
                            name: "DEBRİYAJ GÜÇLENDİRİCİSİ",
                            url: "debriyaj-guclendiricisi",
                            nameEn: "CLUTCH BOOSTER", urlEn: "clutch-booster",
                        },
                        { name: "DEBRİYAJ RULMANI", nameEn: "CLUTCH BEARING", urlEn: "clutch-bearing", url: "debriyaj-rulmani" },
                    ],
                },
                {
                    name: "TREYLER GRUBU",
                    nameEn: "TRAILER GROUP",
                    urlEn: "trailer-group",
                    url: "treyler-grubu",
                    submenus: [
                        { name: "BEŞİNCİ TEKER", nameEn: "FIFTH WHEEL", urlEn: "fifth-wheel", url: "besinci-teker" },
                        { name: "DORSE AYAĞI", nameEn: "LANDING GEAR", urlEn: "landing-gear", url: "dorse-ayagi" },
                    ],
                },
                {
                    name: "SENSÖR GRUBU",
                    nameEn: "SENSOR GROUP",
                    urlEn: "sensor-group",
                    url: "sensor-grubu",
                    submenus: [
                        {
                            name: "ABS SENSÖR KABLOSU",
                            nameEn: "ABS SENSOR CABLE",
                            urlEn: "abs-sensor-cable",
                            url: "abs-sensor-kablosu",
                        },
                    ],
                },
            ],
        };
    },
    methods: {
        toggleMenu(index) {
            this.isMenuOpen[index - 1] = !this.isMenuOpen[index - 1];
        },
        isActive(url) {
                return window.location.href.indexOf(url) !== -1;
            },

        setActive(url) {

            this.menus.forEach(menu => {
                if (menu.url === url || (menu.submenus && menu.submenus.some(submenu => submenu.url === url))) {
                    // Set the active sub-menu as well as its parent menu as active
                    menu.isActive = true;
                    if (menu.submenus) {
                        const parentMenu = this.menus.find(m => m.submenus && m.submenus.some(sub => sub
                            .url === url));
                        if (parentMenu) {
                            parentMenu.isActive = true;
                            this.isMenuOpen[this.menus.indexOf(parentMenu)] =
                                true; // Set the parent menu as open
                        }
                    }
                } else {
                    menu.isActive = false;
                    if (menu.submenus) {
                        menu.submenus.forEach(submenu => {
                            submenu.isActive = false;
                        });
                    }
                }

            });
        },
    },



    mounted() {
        const isFrenGrubuPage = window.location.href.indexOf('fren-grubu') !== -1 || window.location.href.indexOf('brake-group') !== -1;
        if (isFrenGrubuPage) {
            const item28 = document.getElementById('item-28');
            if (item28) {
                item28.classList.add('link-active');
            }

            this.isMenuOpen[0] = true;
        }
        const itemElements = document.querySelectorAll('[id^="item-"]');
        if (itemElements) {
            itemElements.forEach(item => {
                item.addEventListener('click', () => {
                    itemElements.forEach(el => el.classList.remove('link-active'));
                    item.classList.add('link-active');
                });
            });
        }
        const productCategory = document.getElementById('product-category');
        const filterMenu = document.getElementById('filter-menu');
        if (productCategory && filterMenu) {
            const productCategoryTop = productCategory.offsetTop;
            const productCategoryHeight = productCategory.offsetHeight;
            const filterMenuTop = filterMenu.offsetTop;
            const filterMenuHeight = filterMenu.offsetHeight + productCategoryHeight;
            const filterMenuHeightPosition = filterMenuHeight - filterMenuHeight;
            window.addEventListener('scroll', () => {
                const windowHeight = window.innerHeight;
                const scrollPosition = window.scrollY;
                if (window.innerWidth > 900 &&
                    scrollPosition >= filterMenuTop &&
                    scrollPosition <= filterMenuTop + filterMenuHeightPosition) {
                    const parentWidth = filterMenu.parentElement.offsetWidth;
                    filterMenu.style.position = 'fixed';
                    filterMenu.style.top = '0';
                    filterMenu.style.width = `${parentWidth}px`;
                } else {
                    filterMenu.style.position = 'relative';
                    filterMenu.style.padding = '0';
                }
                if (window.innerWidth > 900 &&
                    scrollPosition >=
                    filterMenuTop + filterMenuHeightPosition) {
                    filterMenu.style.position = 'relative';
                    filterMenu.style.top = `${filterMenuHeightPosition}px`;
                }
            });
        }
    },
    components: {
        Header
    }
};
</script>
