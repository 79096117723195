<template>
    <div class="d-flex offer-list-item justify-content-between w-100">
        <p class="product-no">{{product.eku_number}}</p>
        <div class="product-names text-left">
            <p class="product-name text-left">{{product.product_name}}</p>
            <p class="product-ref mb-0">{{ product.oe_number }} / REF. NO.</p>
        </div>
        <div class="pieces">
            <div class="number d-flex align-items-center">
                <span class="minus" @click="removeProduct">-</span>
                <input class="pieces-input" type="text" :value="count" />
                <span class="plus" @click="addProduct">+</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "OfferProduct",
    props: {
        product: {
            required: true,
        }
    },
    data() {
       return {
           count: 0
       }
    },
    mounted() {
        this.setProductCount(this.product.id);
    },
    methods: {
        removeProduct() {
            let products = this.$cookie.getCookie('products');
            let data = ([]).toString();
            if (products) {
                let array = products.split(',');
                let key = this.getKeyByValue(array, this.product.id);
                array.splice(key, 1);
                data = array.toString();
            }
            this.$cookie.setCookie('products', data);
            this.$emit('decrease-count');
            this.count--;
        },
        addProduct() {
            let products = this.$cookie.getCookie('products');
            let data = ([]).toString();
            if (products) {
                let array = products.split(',');
                array.push(this.product.id);
                data = array.toString();
            }else {
                data = ([this.product.id]).toString();
            }
            this.$cookie.setCookie('products', data);
            this.$emit('increase-count');
            this.count++;
        },
        getKeyByValue(object, value) {
            return Object.keys(object).find(key => object[key] === value);
        },
        setProductCount(id){
            let products = this.$cookie.getCookie('products').split(',');
            const counts = {};

            products.forEach(element => {
                counts[element] = (counts[element] || 0) + 1;
            });

            this.count = counts[id];
        },
    }
}
</script>

<style scoped>

</style>
