<template>
    <div class="col-12 col-md-3">
        <div
            class="text-center text-white p-2"
            style="background-color: #ef353d; border-radius: 5px 5px 0px 0px"
        >
            <span v-if="isEnglish">PRODUCT CATEGORIY</span>
            <span v-else>ÜRÜN KATEGORİLERİ</span>
        </div>
        <div
            class="filter-menu"
            id="filter-menu"
            style="
                background-color: #f8f8f8;
                border-radius: 0px 0px 5px 5px;
                margin-bottom: 85px !important;
            "
        >
            <ul class="list-style-none p-0">
                <li
                    v-for="(menu, index) in menus"
                    :key="index"
                    class="menu-list-type"
                >
                    <div class="d-flex justify-content-between">
                        <!-- <a :href="menu.url" :class="{ 'link-active': isActive(menu.url) }" class="text-decoration-none menu-list-type" @click="setActive(menu.url)">
                {{ menu.name }}
              </a> -->
                        <a
                            :href="isEnglish ? menu.urlEn : menu.url"
                            :class="{
                                'link-active': isActive(
                                    isEnglish ? menu.urlEn : menu.url
                                ),
                            }"
                            class="text-decoration-none menu-list-type"
                            @click="
                                setActive(isEnglish ? menu.urlEn : menu.url)
                            "
                        >
                            {{ isEnglish ? menu.nameEn : menu.name }}
                        </a>
                        <!-- <i :id="'menu-plus' + (index + 1)" @click="toggleMenu(index + 1)" :class="[
                'fas',
                
                'plus-color',
                {
                  'fa-plus': !isMenuOpen[index],
                  'fa-minus': isMenuOpen[index],
                  
                },
              ]"></i> -->
                        <span
                            :id="'menu-plus' + (index + 1)"
                            @click="toggleMenu(index + 1)"
                            :class="['plus-color']"
                            style="font-size: 30px"
                        >
                            {{ isMenuOpen[index] ? "-" : "+" }}
                        </span>
                    </div>
                    <ul
                        :id="'open-menu-' + (index + 1)"
                        class="list-style-none"
                        :class="{
                            'open-menu-passive': !isMenuOpen[index],
                            'open-menu-active': isMenuOpen[index],
                        }"
                    >
                        <li
                            v-for="(submenu, subIndex) in menu.submenus"
                            :key="subIndex"
                            class="menu-list-type"
                        >
                            <!-- <a :class="{ 'link-active': isActive(submenu.url) }"  :href="submenu.url" class="text-decoration-none menu-list-type">{{ submenu.name }}</a>
                  -->
                            <a
                                :class="{
                                    'link-active': isActive(
                                        isEnglish ? submenu.urlEn : submenu.url
                                    ),
                                }"
                                :href="isEnglish ? submenu.urlEn : submenu.url"
                                class="text-decoration-none menu-list-type"
                            >
                                {{ isEnglish ? submenu.nameEn : submenu.name }}
                            </a>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        this.handleScrollPosition();
        const currentUrl = window.location.pathname; // URL'in yol kısmını alır
        const firstSegment = currentUrl.split("/")[1]; // İlk segmenti alır (örneğin, 'en')
        const isEnglish = firstSegment === "en"; // İlk segment 'en' ise true döner
        return {
            getLink: "",
            isMenuOpen: Array(6).fill(false),
            isEnglish: isEnglish,
            products: [
                {
                    name: "Fren Kampanası",
                    url: "fren-kampanasi",
                    image: "/images/kampana.jpg",
                    alt: "Fren Kampanası Image",
                },
                {
                    name: "Fren Kampanası",
                    url: "fren-kampanasi",
                    image: "/images/kampana.jpg",
                    alt: "Fren Kampanası Image",
                },
                {
                    name: "Fren Diski",
                    url: "fren-diski",
                    image: "/images/disc.jpg",
                    alt: "Fren Diski Image",
                },
                {
                    name: "Kampana Balata",
                    url: "kampana-balata",
                    image: "/images/kampana.jpg",
                    alt: "Kampana Balata Image",
                },
                {
                    name: "Disk Balata",
                    url: "disk-balata",
                    image: "/images/disc.jpg",
                    alt: "Disk Balata Image",
                },
                {
                    name: "Fren Ayar Kolu",
                    url: "fren-ayar-kolu",
                    image: "/images/yapisi.jpg",
                    alt: "Fren Ayar Kolu Image",
                },
                {
                    name: "Fren Koruğu",
                    url: "fren-korugu",
                    image: "/images/ana-bilesen.jpg",
                    alt: "Fren Koruğu Image",
                },
                {
                    name: "Fren Pabucu",
                    url: "fren-pabucu",
                    image: "/images/disc.jpg",
                    alt: "Fren Pabucu Image",
                },
                {
                    name: "Fren Kaliperi",
                    url: "fren-kaliperi",
                    image: "/images/kampana.jpg",
                    alt: "Fren Kaliperi Image",
                },
                {
                    name: "Poyra",
                    url: "poyra",
                    image: "/images/nasil-calisir.jpg",
                    alt: "Poyra Image",
                },
            ],
            menus: [
                {
                    name: "FREN GRUBU",
                    nameEn: "BBRAKE GROUP",
                    url: "fren-grubu",
                    urlEn: "brake-group",
                    submenus: [
                        {
                            name: "FREN KAMPANASI",
                            nameEn: "BRAKE DRUM",
                            url: "fren-kampanasi",
                            urlEn: "brake-drum",
                        },
                        {
                            name: "FREN DİSKİ",
                            nameEn: "BRAKE DİSC",
                            url: "fren-diski",
                            urlEn: "brake-disc",
                        },
                        {
                            name: "KAMPANA BALATA",
                            nameEn: "BRAKE LİNİNG",
                            url: "kampana-balata",
                            urlEn: "brake-lining",
                        },
                        {
                            name: "DİSK BALATA",
                            nameEn: "BRAKE PAD",
                            url: "disk-balata",
                            urlEn: "brake-pad",
                        },
                        {
                            name: "FREN AYAR KOLU",
                            nameEn: "SLACK ADJUSTER",
                            url: "fren-ayar-kolu",
                            urlEn: "slack-adjuster",
                        },
                        {
                            name: "FREN KÖRÜĞÜ",
                            nameEn: "BRAKE CHAMBER",
                            url: "fren-korugu",
                            urlEn: "brake-chamber",
                        },
                        {
                            name: "FREN PABUCU",
                            nameEn: "BRAKE SHOE",
                            url: "fren-pabucu",
                            urlEn: "brake-shoe",
                        },
                        {
                            name: "FREN KALİPERİ",
                            nameEn: "BRAKE CALİPER",
                            url: "fren-kaliperi",
                            urlEn: "brake-caliper",
                        },
                        {
                            name: "POYRA",
                            nameEn: "WHELLHUB",
                            url: "poyra",
                            urlEn: "wheelhub",
                        },
                    ],
                },
                {
                    name: "HAVALI FREN GRUBU",
                    nameEn: "AIR BRAKE SYSTEM",
                    urlEn: "air-brake-system",
                    url: "havali-fren-grubu",
                    submenus: [
                        {
                            name: "HAVA KURUTUCUSU KARTUŞU",
                            nameEn: "AIR DRYER CARTRIDGE",
                            urlEn: "air-dryer-cartridge",
                            url: "hava-kurutucusu-kartusu",
                        },
                        {
                            name: "HAVA KURUTUCUSU",
                            nameEn: "AIR DRYER",
                            urlEn: "air-dryer",
                            url: "hava-kurutucusu",
                        },
                        {
                            name: "VALFLER",
                            nameEn: "VALVES",
                            urlEn: "valves",
                            url: "valfler",
                        },
                    ],
                },
                {
                    name: "MOTOR GRUBU",
                    nameEn: "MOTOR GROUP",
                    urlEn: "motor-group",
                    url: "motor-grubu",
                    submenus: [
                        {
                            name: "YAKIT POMPASI",
                            nameEn: "FUL PUMP",
                            urlEn: "ful-pump",
                            url: "yakit-pompasi",
                        },
                        {
                            name: "YAĞ POMPASI",
                            nameEn: "OIL PUMP",
                            urlEn: "oil-pump",
                            url: "yag-pompasi",
                        },
                        {
                            name: "SU POMPASI",
                            nameEn: "WATER PUMP",
                            urlEn: "water-pump",
                            url: "su-pompasi",
                        },
                    ],
                },
                {
                    name: "ŞANZIMAN GRUBU",
                    nameEn: "TRANSMISSION GROUP",
                    urlEn: "transmission-group",
                    url: "sanziman-grubu",
                    submenus: [
                        {
                            name: "DEBRİYAJ DİSKİ",
                            nameEn: "CLUTCH DISC",
                            urlEn: "clutch-disc",
                            url: "debriyaj-diski",
                        },
                        {
                            name: "DEBRİYAJ MERKEZİ",
                            nameEn: "CLUTCH MASTER",
                            urlEn: "clutch-master",
                            url: "debriyaj-merkezi",
                        },
                        {
                            name: "DEBRİYAJ BASKISI",
                            nameEn: "CLUTCH PRESSURE",
                            urlEn: "clutch-pressure",
                            url: "debriyaj-baskisi",
                        },
                        {
                            name: "DEBRİYAJ ÇATALI",
                            nameEn: "CLUTCH FORK",
                            urlEn: "clutch-fork",
                            url: "debriyaj-catali",
                        },
                        {
                            name: "DEBRİYAJ GÜÇLENDİRİCİSİ",
                            url: "debriyaj-guclendiricisi",
                            nameEn: "CLUTCH BOOSTER",
                            urlEn: "clutch-booster",
                        },
                        {
                            name: "DEBRİYAJ RULMANI",
                            nameEn: "CLUTCH BEARING",
                            urlEn: "clutch-bearing",
                            url: "debriyaj-rulmani",
                        },
                    ],
                },
                {
                    name: "TREYLER GRUBU",
                    nameEn: "TRAILER GROUP",
                    urlEn: "trailer-group",
                    url: "treyler-grubu",
                    submenus: [
                        {
                            name: "BEŞİNCİ TEKER",
                            nameEn: "FIFTH WHEEL",
                            urlEn: "fifth-wheel",
                            url: "besinci-teker",
                        },
                        {
                            name: "DORSE AYAĞI",
                            nameEn: "LANDING GEAR",
                            urlEn: "landing-gear",
                            url: "dorse-ayagi",
                        },
                    ],
                },
                {
                    name: "SENSÖR GRUBU",
                    nameEn: "SENSOR GROUP",
                    urlEn: "sensor-group",
                    url: "sensor-grubu",
                    submenus: [
                        {
                            name: "ABS SENSÖR KABLOSU",
                            nameEn: "ABS SENSOR CABLE",
                            urlEn: "abs-sensor-cable",
                            url: "abs-sensor-kablosu",
                        },
                    ],
                },
            ],
        };
    },

    methods: {
        isMenuGroupActive(submenus) {
            return submenus.some((submenu) =>
                this.isActive(this.isEnglish ? submenu.urlEn : submenu.url)
            );
        },
        toggleMenu(index) {
            this.isMenuOpen[index - 1] = !this.isMenuOpen[index - 1];
        },
        isActive(url) {
            const currentUrl = window.location.href;

            // URL'nin tam olarak eşleşmesini kontrol et
            return (
                currentUrl === url ||
                currentUrl.startsWith(url + "/") ||
                currentUrl.endsWith(url)
            );
        },
        setActive(url) {
            this.menus.forEach((menu) => {
                const subMenuActive =
                    menu.submenus &&
                    menu.submenus.some((submenu) => submenu.url === url);
                if (menu.url === url || subMenuActive) {
                    menu.isActive = true;
                    if (menu.submenus) {
                        const parentMenu = this.menus.find(
                            (m) =>
                                m.submenus &&
                                m.submenus.some((sub) => sub.url === url)
                        );
                        if (parentMenu) {
                            parentMenu.isActive = true;
                        }
                    }
                } else {
                    menu.isActive = false;
                    if (menu.submenus) {
                        menu.submenus.forEach((submenu) => {
                            submenu.isActive = false;
                        });
                    }
                }
            });
        },

        handleScrollPosition() {
            const productCategory = document.getElementById("product-category");
            const filterMenu = document.getElementById("filter-menu");
            if (productCategory && filterMenu) {
                const productCategoryHeight = productCategory.offsetHeight;
                const filterMenuTop = filterMenu.offsetTop;
                const filterMenuHeight =
                    filterMenu.offsetHeight + productCategoryHeight;
                const filterMenuHeightPosition =
                    filterMenuHeight - filterMenuHeight;

                window.addEventListener("scroll", () => {
                    const scrollPosition = window.scrollY;
                    const innerWidth = window.innerWidth;

                    if (
                        innerWidth > 900 &&
                        scrollPosition >= filterMenuTop &&
                        scrollPosition <=
                            filterMenuTop + filterMenuHeightPosition
                    ) {
                        const parentWidth =
                            filterMenu.parentElement.offsetWidth;
                        filterMenu.style.position = "fixed";
                        filterMenu.style.top = "0";
                        filterMenu.style.width = `${parentWidth}px`;
                    } else {
                        filterMenu.style.position = "relative";
                        filterMenu.style.padding = "0";
                    }

                    if (
                        innerWidth > 900 &&
                        scrollPosition >=
                            filterMenuTop + filterMenuHeightPosition
                    ) {
                        filterMenu.style.position = "relative";
                        filterMenu.style.top = `${filterMenuHeightPosition}px`;
                    }
                });
            }
        },
    },
    mounted() {
        this.menus.forEach((menu, index) => {
            const isActive = menu.submenus
                ? this.isMenuGroupActive(menu.submenus)
                : this.isActive(this.isEnglish ? menu.urlEn : menu.url);
            if (isActive) {
                this.isMenuOpen[index] = true;
            }
        });
    },
};
</script>

<style lang="scss" scoped></style>
