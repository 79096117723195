<template>
    <div class="col-lg-9 col-md-10 offset-lg-2 offset-md-1">
        <div class="product-list-item py-6 position-relative">
            <div class="row">
                <div class="col-lg-7 align-self-center">
                    <div class="d-flex justify-content-around align-items-center">
                        <!-- EKU No. -->
                        <div @click="toggleInfoCard" class="product-no cursor-pointer px-2" >
                            <h6 class="no text-secondary mb-0">{{ product.eku_number }}</h6>
                            <span class="info" v-if="this.product.locale == 'tr'">EKU NO</span>
                            <span class="info" v-else>EKU NUM</span>
                        </div>
                        <!-- Media -->
                        <div @click="toggleInfoCard" class="product-img d-flex cursor-pointer justify-content-start px-2" v-if="product.media" >
                            <img class="w-100 multiply" :src="product.media.url" alt="" />
                        </div>
                        <div v-else class="product-img d-flex cursor-pointer justify-content-start px-2">
                            <img class="w-100 multiply" src="/assets/no-img.png" alt="">
                        </div>
                        <!-- Product Name -->
                        <h6 @click="toggleInfoCard" class="product-name cursor-pointer mb-0 px-2 w-200px">{{ product.product_name }}</h6>
                    </div>
                </div>
                <div class="col-lg-5 align-self-center">
                    <div class="d-flex justify-content-around align-items-center">
                        <!-- OE Number -->
                        <div @click="toggleInfoCard" class="product-ref cursor-pointer px-2">
                            <h6 class="no text-muted mb-0">{{ product.oe_number }}</h6>
                            <span class="info" v-if="this.product.locale == 'tr'">OE NO</span>
                            <span class="info" v-else>OE NUM</span>
                        </div>
                        <i class="fas fa-info px-2 cursor-pointer" @click="toggleInfoCard"></i>
                        <button @click="addToList" class="add-btn d-flex align-items-center justify-content-center position-relative px-2">
                            <svg width="17" height="17" fill="none" v-if="showAddButton">
                                <path
                                    d="M16.156 7.715H9.281V.84a.37.37 0 00-.344-.344H7.563a.339.339 0 00-.344.344v6.875H.344A.339.339 0 000 8.059v1.375c0 .214.129.343.344.343h6.875v6.875c0 .215.129.344.343.344h1.375a.339.339 0 00.344-.344V9.777h6.875a.339.339 0 00.344-.343V8.059a.37.37 0 00-.344-.344z"
                                />
                            </svg>
                            <svg
                                xmlns:svg="http://www.w3.org/2000/svg"
                                v-else-if="showLoadingButton"
                                xmlns="http://www.w3.org/2000/svg"
                                xmlns:xlink="http://www.w3.org/1999/xlink"
                                version="1.0"
                                width="17"
                                height="17"
                                viewBox="0 0 128 128"
                                xml:space="preserve"
                            >
                                <rect x="0" y="0" width="100%" height="100%" fill="none" />
                                <g>
                                    <path d="M64 9.75A54.25 54.25 0 0 0 9.75 64H0a64 64 0 0 1 128 0h-9.75A54.25 54.25 0 0 0 64 9.75z" fill="#000000" />
                                    <animateTransform
                                        attributeName="transform"
                                        type="rotate"
                                        from="0 64 64"
                                        to="360 64 64"
                                        dur="1000ms"
                                        repeatCount="indefinite"
                                    ></animateTransform>
                                </g>
                            </svg>
                            <svg xmlns="http://www.w3.org/2000/svg" v-else width="17" height="17" viewBox="0 0 24 24">
                                <path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z" />
                            </svg>
                            <span class="tooltip-custom" v-if="this.locale =='en'">ADD TO LIST</span>
                            <span class="tooltip-custom" v-else>LİSTEYE EKLE</span>
                        </button>
                    </div>
                </div>
            </div>
            <!-- info -->
            <div class="overlay-info" v-if="infoCard"></div>
            <div class="product-info-content bg-white d-flex flex-lg-row flex-column w-100 p-lg-6 p-md-4 p-3" v-if="infoCard">
                <div class="closed cursor-pointer" @click="toggleInfoCard"></div>
                <div class="col">
                    <h5 class="product-info-name font-weight-bold">{{ product.product_name }}</h5>
                    <h6 class="no text-secondary mb-0">{{ product.eku_number }}</h6>
                    <span class="info" v-if="this.product.locale == 'tr'">EKU NO.</span>
                    <span class="info" v-else>EKU NO.</span>
                    <figure class="mt-7" v-if="product.product_details_media">
                        <img class="w-100 multiply" :src="product.product_details_media.url"  alt="" />
                    </figure>
                </div>
                <div class="col">
                    <ul class="list-inline">
                        <!-- EKU Number -->
                        <li class="d-flex justify-content-between font-weight-bold mb-2">
                            <span class="font-s-18px text-muted" v-if="this.product.locale == 'en'">OE NUMBER</span><span class="font-s-18px text-muted" v-else>OE NUMARASI</span> <span class="font-s-18px text-secondary">{{ product.oe_number }}</span>
                        </li>
                        <!-- APPLICATIONS -->
                        <li class="d-flex justify-content-between font-weight-bold mb-2">
                            <span class="font-s-18px text-muted" v-if="this.product.locale =='en'">BRANDS</span>  <span class="font-s-18px text-muted" v-else>MARKALAR</span><span class="font-s-18px text-secondary" v-for="application in product.applications">{{ application.title }}</span>
                        </li>
                    </ul>
                    <ul class="list-inline" v-for="(value,key) in product" :key="key">
                        <li class="d-flex justify-content-between font-weight-bold mb-2" v-if="fieldArray.indexOf(key) <= -1 && value != null && value.value !== null">

                            <template v-if="value.value"><span class="font-s-18px text-muted">{{ toLanguage(key)}}</span> <span class="font-s-18px text-secondary" >{{ value.label }}</span></template>
                            <template v-else><span class="font-s-18px text-muted">{{ toLanguage(key) }}</span> <span class="font-s-18px text-secondary" >{{ value }}</span></template>
                        </li>
                    </ul>
                    <ul class="list-inline" v-if="product.compatible_products.length">
                        <!-- Alternative OE No. -->
                        <li class="d-flex justify-content-between font-weight-bold mb-2">
                            <span class="font-s-18px text-muted">
                                ALTERNATIVE <br/>
                                OE NUMBERS
                            </span>
                            <span class="font-s-18px text-secondary" v-for="(compatible, index) in product.compatible_products" :key="index">
                                <p class="mb-1">{{ compatible.title }}</p>
                            </span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import * as ProductService from '../services/ProductService';

export default {
    name: 'Product',
    props: {
        product: {
            required: true,
            type: Object,
        },
    },
    data() {
        return {
            infoCard: false,
            showAddButton: true,
            showLoadingButton: false,
            fieldArray: ["oe_number", "eku_number", "product_name", "product_details_media", "media", "card_image", "product_type", "product_category", "competitor_number", "compatible_products", "eku", "applications", "title", "amp_url", "api_url", "blueprint", "collection", "date", "edit_url", "id", "is_entry", "last_modified", "locale", "mount", "order", "parent", "permalink", "private", "published", "slug", "status",  "updated_at", "updated_by", "uri", "url"],
            language: [
                { key: 'kalinlik_mm', tr: 'Kalınlık MM', en: 'Thickness MM'  },
                { key: 'kaliper_sistemi', tr: 'Kaliper Sistemi', en: 'Caliper System'},
                { key: 'montaj_konumu' , tr: 'Montaj Konumu', en: 'Mounting Position'},
                { key: 'uzunluk_mm' , tr: 'Uzunluk MM', en: 'Length MM'},
                { key: 'wva_number' , tr: 'WVA Numarası', en: 'WVA Number'},
                { key: 'yukseklik_mm' , tr: 'Yükseklik MM', en: 'Height MM'},
                { key: 'gobek_deligi_o_mm' , tr: 'Göbek Deliği O MM', en: 'Hub Hole O MM'},
                { key: 'disc_o_mm' , tr: 'Disk O MM', en: 'Disc O MM'},
                { key: 'brake_distance_mm' , tr: 'Fren Mesafesi MM', en: 'Brake Distance MM'},
                { key: 'bijon_eksen_o_mm' , tr: 'Bijon Eksen O MM', en: 'Wheel Axis O MM'},
                { key: 'bijon_delik_sayisi' , tr: 'Bijon Delik Sayısı', en: 'Number Of Bolt Holes'},
                { key: 'bijon_delik_o_mm' , tr: 'Bijon Delik O MM', en: 'Bolt Hole O MM'},
                { key: 'description' , tr: 'Açıklama', en: 'Description'},
                { key: 'reference_number' , tr: 'Referans Numarası', en: 'Reference Number'},
                { key: 'bore_o_mm' , tr: 'Bore O MM', en: 'bore O MM'},
                { key: 'dis_cap_o_mm' , tr: 'Dış Çap O MM', en: 'Outer Diameter O MM'},
                { key: 'ic_yari_cap_1_mm' , tr: 'İç Yarı Çap 1 MM', en: 'Inner Radius 1 MM'},
                { key: 'ic_yari_cap_2_mm' , tr: 'İç Yarı Çap 2 MM', en: 'Inner Radius 2 MM'},
                { key: 'KORUK TIPI' , tr: 'Koruk Tipi', en: 'Bellows Type'},
                { key: 'port' , tr: 'Port', en: 'Port'},
                { key: 'strok' , tr: 'Strok', en: 'Stroke'},
                { key: 'tip' , tr: 'Tip', en: 'Type'},
                { key: "oe_number", tr: "OE Numarası", en: "OE Number" },
                { key: 'itme_cabugu_kafasi' , tr: 'İtme Çubuğu Kafası', en: 'Push Rod Head'},
                { key: 'kampana_o_mm' , tr: 'Kampana O MM', en: 'Bell O MM'},
                { key: 'percin_turu_o' , tr: 'Perçin Türü O', en: 'Rivet type O'},
                { key: 'delik_sayisi' , tr: 'Delik Sayısı', en: 'Number Of Holes'},
                { key: 'delik_o' , tr: 'Delik O', en: 'Hole O'},
                { key: 'genislik' , tr: 'Genişlik', en: 'Width'},
                { key: 'o_mm' , tr: 'O MM', en: 'O MM'},
                { key: 'seri' , tr: 'Seri', en: 'Series'},
                { key: 'parametre' , tr: 'Parametre', en: 'Parameter'},
                { key: 'poyra_profili' , tr: 'Poyra Profili', en: 'Hub Profile'},
                { key: 'motor' , tr: 'Motor', en: 'Motor'},
                { key: 'dis_sayisi' , tr: 'Diş Sayısı', en: 'Number Of Teeth'},
                { key: 'product_name' , tr: 'Ürün Adı', en: 'Product Name'},
                { key: 'product_type' , tr: 'Ürün Tipi', en: 'Product Type'},
            ]
        }
    },
    methods: {
        toLanguage(key){
            var item = this.language.find(i => i.key == key);
            if(this.product.locale == 'tr'){
                return item.tr;
            }
            else{
                return item.en;
            }
        },
        toggleInfoCard() {
            this.infoCard = !this.infoCard
        },
        addToList() {
            this.showAddButton = false
            this.showLoadingButton = true
            setTimeout(() => {
                this.showLoadingButton = false
            }, 1000)
            setTimeout(() => {
                this.showAddButton = true
            }, 3000)

            let products = this.$cookie.getCookie('products')
            let data = [].toString()
            if (products) {
                let array = products.split(',')
                array.push(this.product.id)
                data = array.toString()
            } else {
                data = [this.product.id].toString()
            }
            this.$cookie.setCookie('products', data)
            this.$emit('reload-list')
        },
    },
}
</script>

<style lang="scss" scoped>
.overlay-info {
    background: rgba(0, 0, 0, 0.7);
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
}
.product-info-content {
    z-index: 101 !important;
}
</style>
