<template>
    <div class="news-detail pt-lg-0 pt-5 pb-lg-25 pb-sm-7 pb-20">

        <section class="">
            <div class="container">
                <div class="row">
                    <div class="d-flex justify-content-start align-items-center gap-2 flex-wrap">
                        <a href="/" class="text-decoration-none mr-1" style="color: black;"> {{ isEnglish ? "Home"
                            : "Anasayfa" }} </a>
                        <span style="font-size: 30px;" class="mr-1">></span>
                        <a href="products" class="text-decoration-none text-body"> {{ isEnglish ? "Products" :
                            "Ürünler" }} </a>
                        <span style="font-size: 30px" class="mr-1">></span>
                        <a :href="isEnglish ? 'brake-group' : 'fren-grubu'"
                            class="text-decoration-none text-body mr-1">{{ isEnglish ? "Brake Group" :
                                "Fren Grubu" }}
                        </a>
                        <span style="font-size: 30px" class="mr-1">></span>
                        <a :href="isEnglish ? 'brake-drum' : 'fren-kampanasi'"
                            class="text-decoration-none text-danger">{{ isEnglish ? "Brake Drum" :
                                "Fren Kampanasi" }}</a>
                    </div>
                </div>
            </div>
        </section>
        <section class="container mt-1 product-contain first-banner-color">
            <div class="row align-items-center justify-content-center p-0 pr-5">
                <div class="col-12 col-md-7 p-5">
                    <div class="d-flex flex-column p-5">
                        <h1 class="detail-h1" v-if="!isEnglish">Fren Kampanası Nedir?</h1>
                        <h1 class="detail-h1" v-else>Brake Drum</h1>
                        <div class="hr-blue-product"></div>
                        <p class="mt-3" v-if="!isEnglish">
                            Aracın arka tekerleklerinde bulunan fren kampanası, <b>fren sisteminin</b> bir parçasıdır.
                            Fren kampanası, merkezden dışarıya doğru sıkıştırma yaparak arka tekerlerin dönüş hızını
                            yavaşlatır. Sürtünme oluşturarak tekerleğin dönme hareketini yavaşlatmaya ve aracın
                            savrulmasını engellemeye çalışan <b>fren kampanaları</b>, aracın güvenliği ve performansı
                            için hayati bir rol oynar.
                        </p>
                    </div>
                </div>
                <div class="col-12 col-md-5 p-0">
                    <div class="d-flex justify-content-end">
                        <img src="/images/fren-kampanasi-nedir.png" alt="Fren Kampanas" style="width: 50%;">
                    </div>
                </div>
            </div>
        </section>
        <section class="mt-5 mb-5">
            <div id="menu-row" class="container-fluid">
                <div class="row">
                    <!-- Menu Başlangıç  -->
                    <ViewMenu />
                    <!-- Menu Başlangıç  -->
                    <ViewContent :details="details" :second_tab="second_tab" :first_tab="first_tab"
                        :tab_is_open="tab_is_open" />
                </div>
                <div class="col-12 pb-5 pt-5">
                    <OtherCategories :slider_items="slider_content" />
                </div>
            </div>
        </section>

        <section class="container-fluid"></section>
        <!-- end -->
    </div>
</template>

<script>
import OtherCategories from "../OtherCategories.vue";
import ViewContent from "../ViewContent.vue"
import ViewMenu from "../ViewMenu"

export default {
    props: ['details', 'second_tab', 'first_tab', 'mobile-content', 'slider_content', "tab_is_open"],

    components: {
        OtherCategories,
        ViewContent,
        ViewMenu
    },
    data() {
        const currentUrl = window.location.pathname; // URL'in yol kısmını alır
        const firstSegment = currentUrl.split('/')[1]; // İlk segmenti alır (örneğin, 'en')
        const isEnglish = firstSegment === 'en'; // İlk segment 'en' ise true döner
        return {
            isEnglish: isEnglish,
        };
    },
};
</script>
