<template>
    <div class="mobile-filter filter">
        <div class="mobile-filter-wrapper mb-3">
            <div class="filter-header d-flex justify-content-between bg-secondary p-3" @click="toggleFilter">
                <div class="filter-button d-inline-flex justify-content-between w-100 align-items-center">

                    <span v-if="this.locale == 'en'" class="text-white font-weight-bold">FILTER</span>
                    <span v-else class="text-white font-weight-bold">FİLTRE</span>


<!--<button class="btn btn-secondary m-2" v-if="selectedCategory != null" @click="clearFilter()" type="button"><svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="20px" height="20px" viewBox="0 0 122.88 110.668" enable-background="new 0 0 122.88 110.668" xml:space="preserve"><g><path fill-rule="evenodd" clip-rule="evenodd" d="M91.124,15.645c12.928,0,23.406,10.479,23.406,23.406 c0,12.927-10.479,23.406-23.406,23.406c-12.927,0-23.406-10.479-23.406-23.406C67.718,26.125,78.197,15.645,91.124,15.645 L91.124,15.645z M2.756,0h117.322c1.548,0,2.802,1.254,2.802,2.802c0,0.848-0.368,1.622-0.996,2.139l-10.667,13.556 c-1.405-1.375-2.95-2.607-4.614-3.672l6.628-9.22H9.43l37.975,46.171c0.59,0.516,0.958,1.254,0.958,2.102v49.148l21.056-9.623 V57.896c1.651,1.9,3.548,3.582,5.642,4.996v32.133c0,1.105-0.627,2.064-1.586,2.506l-26.476,12.758 c-1.327,0.773-3.023,0.332-3.798-1.033c-0.258-0.441-0.368-0.92-0.368-1.4V55.02L0.803,4.756c-1.07-1.106-1.07-2.839,0-3.945 C1.355,0.258,2.056,0,2.756,0L2.756,0z M96.93,28.282c1.328-1.349,3.489-1.355,4.825-0.013c1.335,1.342,1.341,3.524,0.013,4.872 l-5.829,5.914l5.836,5.919c1.317,1.338,1.299,3.506-0.04,4.843c-1.34,1.336-3.493,1.333-4.81-0.006l-5.797-5.878l-5.807,5.889 c-1.329,1.349-3.489,1.355-4.826,0.013c-1.335-1.342-1.341-3.523-0.013-4.872l5.83-5.913l-5.836-5.919 c-1.317-1.338-1.3-3.507,0.04-4.843c1.339-1.336,3.492-1.333,4.81,0.006l5.796,5.878L96.93,28.282L96.93,28.282z"></path></g></svg></button>-->
                    <div class="hamburger mr-sm-2">
                        <div class="line line1"></div>
                        <div class="line line2"></div>
                        <div class="line line3"></div>

                    </div>

                </div>
            </div>

            <div class="filter-body-mobile" :class="filter ? 'active' : null">
                <div class="filter-group bg-white rounded p-5 position-relative" :class="selectedCategoryClass">
                    <h6 v-if="this.locale =='en'" class="mb-3 pb-2 mr-md-5 mr-1 ml-3">PRODUCT GROUP</h6>
                    <h6 v-else class="mb-3 pb-2 mr-md-5 mr-1 ml-3">ÜRÜN GRUPLARI</h6>
                    <ul class="filter-list mobile-filter-list list-inline pr-md-5 pr-4">
                        <li
                            v-for="category in categories" :key="category.id"
                            class="mobile-filter-item d-flex justify-content-between align-items-center"
                            :class="selectedCategory && category.id === selectedCategory ? 'active' : null"
                        >
                            <div class="w-100" @click="selectCategory(category.id)">
                                {{ category.title}}
                            </div>
                            <svg fill="none" viewBox="0 0 13 13" style="width: 14px" @click="clearSelectedCategory">
                                <defs />
                                <path
                                    fill="#fff"
                                    d="M7.79 6.5l4.022-3.984.82-.82c.118-.118.118-.313 0-.47l-.859-.859c-.156-.117-.351-.117-.468 0L6.5 5.211 1.656.367C1.54.25 1.344.25 1.188.367l-.86.86c-.117.156-.117.351 0 .468L5.172 6.5.328 11.344c-.117.117-.117.312 0 .469l.86.859c.156.117.351.117.468 0L6.5 7.828l3.984 4.024.82.82c.118.117.313.117.47 0l.859-.86c.117-.156.117-.351 0-.468L7.789 6.5z"
                                />
                            </svg>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="filter-body-mobile" :class="selectedCategory && filter ? 'active' : null">
                <div class="filter-group bg-white rounded p-5 position-relative" :class="!!selectedSubCategory ? 'active' : null">
                    <h6 v-if="this.locale =='en'" class="mb-3 pb-2 mr-md-5 mr-1 ml-3">PRODUCT GROUP</h6>
                    <h6 v-else class="mb-3 pb-2 mr-md-5 mr-1 ml-3">ÜRÜN GRUPLARI</h6>
                    <ul class="filter-list mobile-filter-list list-inline pr-md-5 pr-4">
                        <li
                            v-for="subcategory in subcategories" :key="subcategory.id"
                            :class="selectedSubCategory && subcategory.id === selectedSubCategory ? 'active' : null"
                            class="mobile-filter-item d-flex justify-content-between align-items-center"
                        >
                            <div class="w-100" @click="selectSubCategory(subcategory.id)">
                                {{ subcategory.title}}
                            </div>
                            <svg fill="none" viewBox="0 0 13 13" style="width: 14px" @click="clearSelectedSubCategory">
                                <defs />
                                <path
                                    fill="#fff"
                                    d="M7.79 6.5l4.022-3.984.82-.82c.118-.118.118-.313 0-.47l-.859-.859c-.156-.117-.351-.117-.468 0L6.5 5.211 1.656.367C1.54.25 1.344.25 1.188.367l-.86.86c-.117.156-.117.351 0 .468L5.172 6.5.328 11.344c-.117.117-.117.312 0 .469l.86.859c.156.117.351.117.468 0L6.5 7.828l3.984 4.024.82.82c.118.117.313.117.47 0l.859-.86c.117-.156.117-.351 0-.468L7.789 6.5z"
                                />
                            </svg>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="filter-body-mobile" :class="selectedSubCategory || selectedCategory || filter ? 'active' : null">
                <div class="filter-group bg-white rounded p-5 position-relative" :class="!!selectedApplication || allApplications ? 'active' : null">
                    <h6 v-if="this.locale =='en'" class="mb-3 pb-2 mr-md-5 mr-1 ml-3">BRANDS</h6>
                    <h6 v-else class="mb-3 pb-2 mr-md-5 mr-1 ml-3">MARKALAR</h6>
                    <ul class="filter-list mobile-filter-list list-inline pr-md-5 pr-4" >
                        <li v-if="this.locale ==='tr' && applications.length" :class="allApplications ? 'active' : null " class="mobile-filter-item d-flex justify-content-between align-items-center">
                            <div class="w-100" @click="fetchAllApplications()">
                                Tüm Markalar
                            </div>
                            <svg fill="none" viewBox="0 0 13 13" style="width: 14px" @click="clearSelectedApplicationAll">
                                <defs />
                                <path
                                    fill="#fff"
                                    d="M7.79 6.5l4.022-3.984.82-.82c.118-.118.118-.313 0-.47l-.859-.859c-.156-.117-.351-.117-.468 0L6.5 5.211 1.656.367C1.54.25 1.344.25 1.188.367l-.86.86c-.117.156-.117.351 0 .468L5.172 6.5.328 11.344c-.117.117-.117.312 0 .469l.86.859c.156.117.351.117.468 0L6.5 7.828l3.984 4.024.82.82c.118.117.313.117.47 0l.859-.86c.117-.156.117-.351 0-.468L7.789 6.5z"
                                />
                            </svg>
                        </li>
                         <li v-else-if="applications.length" :class="allApplications ? 'active' : null " class="mobile-filter-item d-flex justify-content-between align-items-center">
                            <div class="w-100" @click="fetchAllApplications()">
                                All Applications
                            </div>
                            <svg fill="none" viewBox="0 0 13 13" style="width: 14px" @click="clearSelectedApplicationAll">
                                <defs />
                                <path
                                    fill="#fff"
                                    d="M7.79 6.5l4.022-3.984.82-.82c.118-.118.118-.313 0-.47l-.859-.859c-.156-.117-.351-.117-.468 0L6.5 5.211 1.656.367C1.54.25 1.344.25 1.188.367l-.86.86c-.117.156-.117.351 0 .468L5.172 6.5.328 11.344c-.117.117-.117.312 0 .469l.86.859c.156.117.351.117.468 0L6.5 7.828l3.984 4.024.82.82c.118.117.313.117.47 0l.859-.86c.117-.156.117-.351 0-.468L7.789 6.5z"
                                />
                            </svg>
                        </li>
                        <li
                            v-for="application in applications" :key="application.id"
                            :class="selectedApplication && application.id === selectedApplication ? 'active' : null"
                            class="mobile-filter-item d-flex justify-content-between align-items-center"
                        >
                            <div class="w-100" @click="selectApplication(application.id)">
                                {{ application.title }}
                            </div>
                            <svg fill="none" viewBox="0 0 13 13" style="width: 14px" @click="clearSelectedApplication">
                                <defs />
                                <path
                                    fill="#fff"
                                    d="M7.79 6.5l4.022-3.984.82-.82c.118-.118.118-.313 0-.47l-.859-.859c-.156-.117-.351-.117-.468 0L6.5 5.211 1.656.367C1.54.25 1.344.25 1.188.367l-.86.86c-.117.156-.117.351 0 .468L5.172 6.5.328 11.344c-.117.117-.117.312 0 .469l.86.859c.156.117.351.117.468 0L6.5 7.828l3.984 4.024.82.82c.118.117.313.117.47 0l.859-.86c.117-.156.117-.351 0-.468L7.789 6.5z"
                                />
                            </svg>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <form class="product-search d-flex mb-3">
            <input v-if="this.locale === 'en'" type="text" class="w-100" v-model="keyword" @keyup.enter="searchAs('basic')" placeholder="Search by product ID" />
            <input v-else type="text" class="w-100" v-model="keyword" @keyup.enter="searchAs('basic')" placeholder="Ürün ID'si İle Arama Yapın" />
            <button type="submit" @click.prevent="searchAs('basic')" class="search_btn border-0 d-flex align-items-center">
                <i class="fas fa-search text-white"></i>
            </button>
        </form>
        <p v-if="this.locale =='tr'" class="ml-2 letter-spacing-002 font-s-14px mb-5" style="color: #666666">*Ürun numaralar örnek çalışmadır.</p>
        <p v-else class="ml-2 letter-spacing-002 font-s-14px mb-5" style="color: #666666">*Item numbers are sample work.</p>

        <div class="mobile-product-list products-general d-md-none d-block mb-7">
            <div class="wrapper">
                <p v-if="isSearchedEmptyContent" class="text-center">{{this.locale == 'en' ? 'We could not find the product you are looking for. Try typing something else' : 'Aradığınız ürünü bulamadık. Başka bir şey yazmayı deneyin'}}</p>
                <div v-else class="product-item active d-flex justify-content-between py-4" v-for="(product, index) in products" :key="index">
                    <MobileProduct :product="product" @reload-list="reloadList" />
                </div>
            </div>
        </div>
        <div class="d-flex justify-content-center">
      <nav v-if="pagination.links" aria-label="Page navigation" class="mt-5">
        <ul class="pagination d-flex align-items-center justify-content-center flex-wrap">

           <li @click="page--" class="btn btn-light">＜</li>
          <li v-for="(item, index) in pagination.links.slice(1, pagination.links.length-1)" :key="index" class="page-item mx-2 my-1" @click="page = item.label">
            <button class="btn" :class="item.label == page ? 'btn-secondary' : 'btn-light'">{{ item.label }}</button>
          </li>
          <li @click="page++" class="btn btn-light">＞</li>

        </ul>
      </nav>
    </div>
    </div>
</template>

<script>
import * as ProductService from '../../services/ProductService'
import Product from '../Product'
import OfferList from '../OfferList'
import axios from '../../axios'
import * as ApplicationService from '../../services/ApplicationService'
import MobileProduct from './MobileProduct'

export default {
    name: 'MobileProductFilter',
    components: { MobileProduct, OfferList, Product },
    props: {
        locale: {
            required: true,
            type: String,
        },
        q: {
            required: false,
            type: String,
        },
        searchedCategory: {
            required: false,
            type: String,
        },
        searchedSubcategory: {
            required: false,
            type: String,
        },
        searchedApplication: {
            required: false,
            type: String,
        },
        advancedSearch: {
            required: false,
            type: Boolean,
        },
        filteredCategory: {
            required: false,
            type: Object,
        },
    },
    data() {
        return {
            categories: [],
            subcategories: [],
            products: [],
            applications: [],
            selectedCategory: null,
            selectedSubCategory: null,
            selectedApplication: null,
            allApplications: false,
            keyword: null,
            page: 1,
            searchType: 'advanced',
            isProductsLoading: false,
            pagination: {},
        }
    },
     mounted() {
        this.searchBox();
        this.fetchProducts();
        this.handleProductCategories();
    },
    methods: {
         searchBox(){
              if(window.location.href.split('?q=')[1] != undefined){
                this.searchType = 'basic'
                this.keyword = window.location.href.split('?q=')[1];
            }
        },
         async fetchProducts(){
            this.isProductsLoading = true;
            let url = "?filter[site:is]=" + this.locale + "&page=" + this.page;
            if (this.searchType === 'advanced') {
                if (this.selectedCategory && !this.selectedSubCategory) {
                    url += '&filter[product_category:contains]=' + this.selectedCategory.split('::')[1]
                }
                if (this.selectedSubCategory && !this.selectedApplication) {
                    url += '&filter[product_category:contains]=' + this.selectedSubCategory.split('::')[1]
                }
                if (this.selectedApplication) {
                    url += "&filter[applications:contains]=" + this.selectedApplication.split('::')[1]
                    url += '&filter[product_category:contains]=' + this.selectedSubCategory.split('::')[1]
                }

                await ProductService.productFilter(url)
                .then(response => {
                    this.products = response.data.data;
                    this.pagination = response.data.meta;
                });
            }else {
                url += "&q=" + this.keyword;
                await ProductService.filter(url)
                .then(response => {
                    this.products = response.data.data;
                    this.pagination = response.data.meta;
                });
            }
            this.isProductsLoading = false;
        },
        reloadList() {
            this.$emit('start-reload')
        },

        toggleFilter() {
            this.filter = !this.filter
            this.clearFilter()
        },


        searchAs(type){
            this.searchType = type;
            if(type === 'basic'){
                this.clearFilter();
            }else{
                this.keyword = null;
            }
            this.fetchProducts();
        },

        async handleProductCategories() {
            await ProductService.categories(this.locale).then((response) => {
                this.categories = response.data.data
            //    this.initialSelectCategoryFilter()
            })
        },
        async handleSubProductCategories() {
            if (this.selectedCategory) {
                await ProductService.categories(this.locale, this.selectedCategory.split('::')[1]).then((response) => {
                    this.subcategories = response.data.data
                    this.selectedSubCategory = null
                })
            } else {
                this.subcategories = []
                this.selectedSubCategory = null
            }
        },
        async handleApplicationCategories() {
            if (this.selectedSubCategory) {
                await ApplicationService.categories(this.locale).then((response) => {
                    this.applications = response.data.data
                    this.selectedApplication = null
                })
            } else {
                this.applications = []
                this.selectedApplication = null
            }
        },

        selectCategory(category, fetch = true) {
            this.selectedCategory = category
            this.handleSubProductCategories()
            if(fetch){
                this.searchType = 'advanced';
                this.fetchProducts()
            }

        },
        selectSubCategory(subcategory, fetch = true) {
            this.selectedSubCategory = subcategory
            this.handleApplicationCategories()
            if(fetch){
                this.searchType = 'advanced';
                this.fetchProducts()
            }
        },
        selectApplication(application, fetch = true){
            this.selectedApplication = application
            if(fetch){
                this.searchType = 'advanced';
                this.fetchProducts()
            }
        },
         clearFilter(){
            this.selectCategory(null, false)
            this.selectSubCategory(null, false)
            this.selectApplication(null, false)
        },
        fetchAllApplications(){
            this.allApplications = true,
            this.selectedApplication = null,
            this.fetchProducts()
        },
        
        search() {
            this.handleProducts(this.advancedSearch)
        },
        searchButton() {
            let url = '?advanced=true'
            if (this.selectedCategory) {
                if (!url) {
                    url += '?category=' + this.selectedCategory.id
                } else {
                    url += '&category=' + this.selectedCategory.id
                }
            }

            if (this.selectedSubCategory) {
                if (!url) {
                    url += '?subcategory=' + this.selectedSubCategory.id
                } else {
                    url += '&subcategory=' + this.selectedSubCategory.id
                }
            }
            if (this.selectedApplication) {
                if (!url) {
                    url += '?application=' + this.selectedApplication.id
                } else {
                    url += '&application=' + this.selectedApplication.id
                }
            }

            if (this.keyword) {
                if (!url) {
                    url += '?q=' + this.keyword
                } else {
                    url += '&q=' + this.keyword
                }
            }

            if (this.locale === 'tr') {
                window.location.href = '/products' + url
            } else {
                window.location.href = '/' + this.locale + '/products' + url
            }
        },
        clearSelectedSubCategory() {
            this.selectedSubCategory = null
            this.clearSelectedApplication()
            this.fetchProducts()
        },
        clearSelectedCategory() {
            this.selectedCategory = null
            this.clearSelectedSubCategory()
            this.fetchProducts()
        },
        clearSelectedApplication() {
            this.selectedApplication = null
            this.fetchProducts()
        },
        clearSelectedApplicationAll(){
           this.allApplications = false;
        },
    },
    computed: {
        isSearchedEmptyContent() {
            return this.products !== null && this.products.length <= 0
        },
        selectedCategoryClass() {
            return this.selectedCategory ? 'active' : null
        },
    },
    watch: {
        "page": function(){
            if(this.page <= 0)
                this.page = 1;
            // TODO: check if next page is available
            this.fetchProducts();
        },
    },
}
</script>

<style scoped>

</style>
