<template>
    <div class="col-12 col-md-8 product-contain2" id="product-section">
        <div class="row align-items-center">
            <section class="container  mb-5">
                <div class="row align-items-center justify-content-center ">
                    <div class="col-12">
                        <div class="tab" >
                            <div class="row w-100">
                                <div class="col-6" >
                                    <button class="w-100"  :class="{ 'active': activeTab === 0 }" @click="activateTab(0)">
                                    {{ isEnglish ? 'PRODUCT DESCRIPTION' : 'ÜRÜN AÇIKLAMA' }}
                                    </button>
                                </div>
                                <div class="col-6">
                                    <button class="w-100"  :class="{ 'active': activeTab === 1  }" @click="activateTab(1)">
                                  {{ isEnglish ? 'OEM CODE' : 'OEM KODLARI' }}
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="tab-content "> 
                            <div v-html="first_tab" class="tab-pane"  :class="{ 'active': activeTab === 0 }"></div>
                            <div v-html="second_tab" class="tab-pane" :class="{ 'active': activeTab === 1  }"></div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>

</template>







<script>
import $ from "jquery";
export default {
    props: ["mobile-content", "first_tab", "second_tab", "third_tab", "tab_is_open","oem"],


    data() {
        const currentUrl = window.location.pathname; // URL'in yol kısmını alır
        const firstSegment = currentUrl.split('/')[1]; // İlk segmenti alır (örneğin, 'en')
        const isEnglish = firstSegment === 'en'; // İlk segment 'en' ise true döner
        return {
            isEnglish: isEnglish,
            activeTab: 0,
            tabs: [
                {
                    name: 'ÜRÜN AÇIKLAMA',
                },
                {
                    name: 'OEM KODLARI',
                },
            ],
        };
    },
    methods: {
        activateTab(index) {
            this.activeTab = index;
        },
    },
    mounted() {
        const table = $('#oem_table').DataTable({
        "searching": true,
        "ordering": true,
        "language": this.isEnglish ? {} : {
            "url": "//cdn.datatables.net/plug-ins/1.10.24/i18n/Turkish.json"
        }
    });
    setTimeout(() => {
        table.draw();
    }, 2000); // 5000 milisaniye = 5 saniye
    },

    
};
</script>
<style>
.tab {
    display: flex;
    justify-content: space-around;
    margin-bottom: 10px;
    gap: 50px;
    width: 100%;
}

.tab button {
    padding: 8px 16px;
    border: 0;
    border-radius: 5px;
    cursor: pointer;
    background-color: #f8f8f8;
    outline: none;
}

.tab button.active {
    background-color: #ef353d !important;
    color: white;
}
.pagination .page-item.disabled .page-link{
    color: #6c757d;
}

.pagination .page-item .page-link{
    color: #ef353d;
}
.pagination .page-item .page-link:focus {
  z-index: 3;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(239, 53, 61, 0.3);
}

.dt-container .form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #ef353d;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(239, 53, 61, 0.3);
}
.pagination .page-item.active .page-link {
  z-index: 3;
  color: #fff !important;
  background-color: #ef353d;
  border-color: #ef353d;
}
table.dataTable td.dt-type-date, table.dataTable td.dt-type-numeric, table.dataTable th.dt-type-date, table.dataTable th.dt-type-numeric{
    text-align: left !important;
}
.menu-list-type div{
    align-items: center !important;
}
</style>

