<template>
  <div>
    <div class="row border-filter">
      <div class="col-lg-9 col-md-10 offset-lg-2 offset-md-1">
        <div class="filter mb-7 bg-secondary">
          <div class="filter-header d-flex justify-content-between">
            <div class="filter-button d-inline-flex align-items-center">
              <div class="hamburger mr-sm-2">
                <div class="line line1"></div>
                <div class="line line2"></div>
                <div class="line line3"></div>
              </div>
              <span
                class="text-white font-weight-normal"
                v-if="this.locale === 'tr'"
                >FİLTRE</span
              >
              <span class="text-white font-weight-normal" v-else>FILTER</span>
            </div>

            <form class="search-filter">
              <div class="search-box d-flex">
                <input
                  type="text"
                  name="q"
                  id="search-input"
                  class="mr-3 search-input w-100"
                  :placeholder="
                    this.locale === 'tr'
                      ? 'Ürün ID\'sine göre arayın (Part No. Ref No.)'
                      : 'Search by product ID (Part No. Ref No.)'
                  "
                  v-model="keyword"
                  @keyup.enter="searchAs('basic')"
                />
                <button
                  type="submit"
                  class="
                    bg-transparent
                    border-0
                    search-button
                    btn btn-secondary
                    d-flex
                    align-items-center
                  "
                >
                  <i class="fas fa-search"></i>
                </button>
              </div>
            </form>
          </div>
          <div class="filter-body pr-3 mt-3">
            <div class="row mb-5">
              <div class="col-lg-4 mb-lg-0 mb-4 h-auto">
                <div class="filter-group bg-white rounded p-5 h-100">
                  <h6
                    class="mb-3 pb-2 mr-md-5 mr-1 ml-5"
                    v-if="this.locale === 'tr'"
                  >
                    ÜRÜN GRUBU
                  </h6>
                  <h6 class="mb-3 pb-2 mr-md-5 mr-1 ml-5" v-else>
                    PRODUCT GROUP
                  </h6>
                  <ul
                    id="filter-list-parent"
                    class="filter-list list-inline pr-md-5 pr-1"
                  >
                    <li
                      v-for="category in categories"
                      :key="category.id"
                      :class="
                        selectedCategory && category.id === selectedCategory
                          ? 'active'
                          : null
                      "
                      @click="selectCategory(category.id)"
                    >
                      {{ category.title }}
                    </li>
                  </ul>
                </div>
              </div>
              <div class="h-auto mb-4 col-lg-4 mb-lg-0">
                <div class="p-5 bg-white rounded filter-group h-100">
                  <h6
                    class="pb-2 mb-3 ml-5 mr-1 mr-md-5"
                    v-if="this.locale === 'tr'"
                  >
                    ÜRÜN ALT GRUBU
                  </h6>
                  <h6 class="pb-2 mb-3 ml-5 mr-1 mr-md-5" v-else>
                    SUB PRODUCT GROUP
                  </h6>
                  <ul
                    id="filter-list"
                    class="pr-1 filter-list list-inline pr-md-5"
                  >
                    <li
                      v-for="subcategory in subcategories"
                      :key="subcategory.id"
                      :class="
                        selectedSubCategory &&
                        subcategory.id === selectedSubCategory
                          ? 'active'
                          : null
                      "
                      @click="selectSubCategory(subcategory.id)"
                    >
                      {{ subcategory.title }}
                    </li>
                  </ul>
                </div>
              </div>
              <div class="h-auto mb-4 col-lg-4 mb-lg-0">
                <div class="p-5 bg-white rounded filter-group h-100">
                  <h6
                    class="pb-2 mb-3 ml-5 mr-1 mr-md-5"
                    v-if="this.locale === 'tr'"
                  >
                    MARKALAR
                  </h6>
                  <h6 class="pb-2 mb-3 ml-5 mr-1 mr-md-5" v-else>
                    BRANDS
                  </h6>
                  <ul
                    id="filter-list"
                    class="pr-1 filter-list list-inline pr-md-5"
                  >
                    <li v-if="this.locale ==='tr' && applications.length" :class="allApplications ? 'active' : '' " @click="fetchAllApplications()">Tüm Markalar</li>
                    <li v-else-if="applications.length" :class="allApplications ? 'active' : '' " @click="fetchAllApplications()">All Applications</li>
                    <li
                      v-for="application in applications"
                      :key="application.id"
                      :class="
                      !allApplications && selectedApplication &&
                        application.id === selectedApplication
                          ? 'active'
                          : null
                      "
                      @click="selectApplication(application.id), allApplications = false"
                    >
                      {{ application.title }}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-4 offset-lg-4">
                <button
                  type="button"
                  id="clear-filter"
                  class="
                    text-center text-white
                    bg-transparent
                    btn
                    custom-button-padding
                    clear-all-filters
                    font-weight-bold
                    d-flex
                    w-100
                    text-lg-right
                    justify-content-lg-end justify-content-center
                    text-uppercase
                  "
                  @click="clearFilter"
                >
                  <span v-if="this.locale === 'tr'"
                    >TÜM FİLTRELERİ TEMİZLE</span
                  >
                  <span v-else>CLEAR ALL FILTERS</span>
                </button>
              </div>
              <div class="col-lg-4">
                <button
                  type="button"
                  id="show-products"
                  class="
                    text-center
                    bg-white
                    shadow-sm
                    btn
                    custom-button-padding
                    filter-show
                    text-secondary
                    font-weight-bold
                    d-flex
                    w-100
                    justify-content-center
                    text-uppercase
                  "
                  @click="searchAs('advanced')"
                >
                  <span v-if="this.locale === 'tr'">ÜRÜNLERİ GÖSTER</span>
                  <span v-else>SHOW PRODUCTS</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <p v-if="isProductsLoading" class="text-center">
      <span v-if="this.locale === 'en'">Loading</span
      ><span v-else>Yükleniyor</span>
    </p>
    <div v-else class="row">
      <div v-if="products.length === 0" class="col-12">
        <p v-if="this.locale === 'en'" class="text-center">
          We could not find the product you are looking for. Try typing
          something else
        </p>
        <p v-else class="text-center">
          Aradığınız ürünü bulamadık. Başka bir şey yazmayı deneyin
        </p>
      </div>
      <Product
        :product="product"
        v-for="(product, index) in products"
        :key="index"
        @reload-list="reloadList"
      />
      <!--            <Product :product="product" @reload-list="reloadList" />-->
    </div>
    <!-- <button @click="page--">Prev</button>
      <p>{{this.page}}</p>
      <button @click="page++">Next</button> -->
    <div class="d-flex justify-content-center">
       <nav v-if="pagination.links" aria-label="Page navigation" class="mt-5">
        <ul class="pagination d-flex justify-content-center flex-wrap">

           <li @click="page--" class="btn btn-light">＜</li>
          <li v-for="(item, index) in pagination.links.slice(1, pagination.links.length-1)" :key="index" class="page-item mx-2" @click="page = item.label">
            <button class="btn" :class="item.label == page ? 'btn-secondary' : 'btn-light'">{{ item.label }}</button>
          </li>
          <li @click="page++" class="btn btn-light">＞</li>

        </ul>
      </nav>
    </div>
  </div>
</template>

<script>
import * as ProductService from '../services/ProductService'
import Product from './Product'
import OfferList from './OfferList'
import axios from '../axios'
import * as ApplicationService from '../services/ApplicationService'

export default {
    name: 'ProductFilter',
    components: { OfferList, Product },
    props: {
        locale: {
            required: true,
            type: String,
        },
        q: {
            required: false,
            type: String,
        },
        searchedCategory: {
            required: false,
            type: String,
        },
        searchedSubcategory: {
            required: false,
            type: String,
        },
        searchedApplication: {
            required: false,
            type: String,
        },
        advancedSearch: {
            required: false,
            type: Boolean,
        },
        filteredCategory: {
            required: false,
            type: Object,
        }
    },
    data() {
        return {
            categories: [],
            subcategories: [],
            products: [],
            applications: [],
            selectedCategory: null,
            selectedSubCategory: null,
            selectedApplication: null,
            keyword: null,
            page: 1,
            searchType: 'advanced',
            isProductsLoading: false,
            pagination: {},
            allApplications: true,
        }
    },
    created() {
        this.searchBox();
        this.handleProductCategories();
        this.fetchProducts();
    },
    mounted() {},
    methods: {
        searchBox(){
            if(window.location.href.split('?q=')[1] != undefined){
                this.searchType = 'basic'
                this.keyword = window.location.href.split('?q=')[1];
            }

        },
        async fetchProducts(){
            this.isProductsLoading = true;
            let url = "?filter[site:is]=" + this.locale + "&page=" + this.page;
            if (this.searchType === 'advanced') {
                if (!this.selectedCategory && !this.selectedSubCategory && !this.selectedApplication) {
                    url += '&filter[product_category:contains]=brake_drum';
                }
                if (this.selectedCategory && !this.selectedSubCategory) {
                    url += '&filter[product_category:contains]=' + this.selectedCategory.split('::')[1]
                }
                if (this.selectedSubCategory && !this.selectedApplication) {
                    url += '&filter[product_category:contains]=' + this.selectedSubCategory.split('::')[1]
                }
                if (this.selectedApplication && this.selectedCategory) {
                    url += "&filter[applications:contains]=" + this.selectedApplication.split('::')[1]
                    url += '&filter[product_category:contains]=' + this.selectedSubCategory.split('::')[1]
                }

                await ProductService.productFilter(url)
                .then(response => {
                    this.products = response.data.data;
                    this.pagination = response.data.meta;
                });
            }else{
                url += "&q=" + this.keyword;
                await ProductService.filter(url)
                .then(response => {
                    this.products = response.data.data;
                    this.pagination = response.data.meta;
                });
            }
            this.isProductsLoading = false;
        },
        searchAs(type){
            this.searchType = type;
            if(type === 'basic'){
                this.clearFilter();
            }else{
                this.keyword = null;
            }
            this.fetchProducts();
        },
        clearFilter(){
            this.selectCategory(null, false)
            this.selectSubCategory(null, false)
            this.selectApplication(null, false)
        },
        reloadList() {
            this.$emit('start-reload');
        },
        async handleProductCategories() {
            await ProductService.categories(this.locale).then((response) => {
                this.categories = response.data.data
            //    this.initialSelectCategoryFilter()
            })
        },
        async handleSubProductCategories() {
            if (this.selectedCategory) {
                await ProductService.categories(this.locale, this.selectedCategory.split('::')[1]).then((response) => {
                    this.subcategories = response.data.data
                    this.selectedSubCategory = null
                })
            } else {
                this.subcategories = []
                this.selectedSubCategory = null
            }
        },
        async handleApplicationCategories() {
            if (this.selectedSubCategory) {
                await ApplicationService.categories(this.locale).then((response) => {
                    this.applications = response.data.data
                    this.selectedApplication = null
                })
            } else {
                this.applications = []
                this.selectedApplication = null
            }
        },
        selectCategory(category, fetch = true) {
            this.selectedCategory = category
            this.handleSubProductCategories()
            if(fetch){
                this.searchType = 'advanced';
                this.fetchProducts()
            }

        },
        selectSubCategory(subcategory, fetch = true) {
            this.selectedSubCategory = subcategory
            this.handleApplicationCategories()
            if(fetch){
                this.searchType = 'advanced';
                this.fetchProducts()
            }
        },
        selectApplication(application, fetch = true){
            this.selectedApplication = application
            if(fetch){
                this.searchType = 'advanced';
                this.fetchProducts()
            }
        },
        fetchAllApplications(){
          this.allApplications = true,
          this.selectedApplication = null,
          this.fetchProducts()
        }
    },
    watch:{
        "page": function(){
            if(this.page <= 0)
                this.page = 1;
            // TODO: check if next page is available
            this.fetchProducts();
        },
    }
}
</script>

<style scoped></style>
